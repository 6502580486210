import React, { useState, useEffect } from 'react'
import Axios from 'axios';
import { NavLink } from 'react-router-dom';

export const Success = () => {
    const [addPlan, setAddPlan] = useState("")
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const plan = window.atob(queryParams.get("plan"));
        Axios.post(`${process.env.REACT_APP_BASE_URL}WebApi/addPlan`,
            { companyId: localStorage.companyId, plan: plan }
        )
            .then((res) => {
                setAddPlan(res.data);
            }).catch((err) => {
                console.log('Payment Error: ', err);
            })
    }, [])

    return (
        <>
            {addPlan === 'Y' ?
                <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '20%' }}>
                    <img src={require("./images/checkmark.gif")} alt="Payment Successful!" width={'100%'} />
                    <h2 style={{ color: '#360A65', marginLeft: 'auto', marginRight: 'auto' }}>Thank You</h2>
                    <NavLink
                        to={"/searchCandidates"}
                        style={{ color: '#fff', width: '100%', marginBottom: 30, marginTop: 20 }}
                        className="banner-btn"
                    >
                        START HIRING NOW
                    </NavLink>
                </div>
                :
                <div style={{height: '450px'}}>Loading...</div>
            }
        </>
    )
}
export default Success