// import logo from './logo.svg';
// import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import SearchCandidates from './SearchCandidates';
import CandidateProfile from './CandidateProfile';
import ChatWithCandidate from './ChatWithCandidate';
import EmployerProfile from './EmployerProfile';
import EditEmployerProfile from './EditEmployerProfile';
import ManageJobs from './ManageJobs';
import FavoriteCandidates from './FavoriteCandidates';
import InterviewAndOfferRequests from './InterviewAndOfferRequests';
import PostJobDescription from './PostJobDescription';
import Pricing from './Pricing';
import ScrollToTop from "./ScrollToTop";
import Navbar from './Navbar';
import Footer from "./Footer";
import NewCandidate from './components/NewCandidate';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import Success from './Success.js';
import Cancel from './Cancel';
import DataDeletionInstructions from './DataDeletionInstructions';

function App() {
  return (
    <>
      <Navbar />
      <ScrollToTop>
        <Routes>
          {/* <Route path='/' element={<Home />} /> */}
          <Route path='/' element={<NewCandidate />} />
          <Route path='/searchcandidates' element={<SearchCandidates />} />
          <Route path='/candidateprofile' element={<CandidateProfile />} />
          <Route path='/chatwithcandidate' element={<ChatWithCandidate />} />
          <Route path='/employerprofile' element={<EmployerProfile />} />
          <Route path='/editemployerprofile' element={<EditEmployerProfile />} />
          <Route path='/managejobs' element={<ManageJobs />} />
          <Route path='/favoritecandidates' element={<FavoriteCandidates />} />
          <Route path='/interviewAndOfferRequests' element={<InterviewAndOfferRequests />} />
          <Route path='/postjobdescription' element={<PostJobDescription />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/newcandidate' element={<Home />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/termsandconditions' element={<TermsAndConditions />} />
          <Route path='/success' element={<Success />} />
          <Route path='/cancel' element={<Cancel />} />
          <Route path='/DataDeletionInstructions' element={<DataDeletionInstructions />} />
          {/* <Route path='/newcandidate' element={<NewCandidate />} /> */}
        </Routes>
      </ScrollToTop>
      <Footer />
    </>
  );
}

export default App;
