import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/themify-icons.css';
import './assets/css/et-line.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/plyr.css';
import './assets/css/flag.css';
import './assets/css/slick.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery.nstSlider.min.css';
import './css/main.css';
import './assets/css/dashboard.css';
import Header from './Header';
import EmployerLeftSideBar from './EmployerLeftSideBar';
import FeatherIcon from 'feather-icons-react';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import { Formik } from "formik";
import * as yup from 'yup';
import { useLocation } from 'react-router-dom'
import ReactJsAlert from "reactjs-alert";
import { useNavigate } from "react-router-dom";

const EditEmployerProfile = () => {
    let navigate = useNavigate();
    let companyProfile = useLocation().state;
    const initialValues = {
        companyName: companyProfile.companyName,
        companyEmail: companyProfile.companyEmail,
        companyPhoneNumber: companyProfile.companyPhoneNumber,
        companyAddress: companyProfile.companyAddress,
        companyAboutUs: companyProfile.companyAboutUs,
        companyCurrentPassword: "",
        companyPassword: "",
        companyConfirmPassword: ""
    }

    const [alertStatus, setAlertStatus] = useState(false);
    const [type, setType] = useState("success");
    const [title, setTitle] = useState("");
    const [image, setImage] = useState(null)
    const [file, setFile] = useState();
    const [fileType, setFileType] = useState("");

    const ReviewSchema = yup.object({
        companyName: yup.string().required('Please enter company name').min(2, 'Company name should contain atleast 3 characters!'),
        companyEmail: yup.string().required('Please enter email id').min(3, 'Email id should contain atleast 3 characters!')
            .test(
                'isAlphabets', 'Please enter valid email id', (val) => {
                    return /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(val);
                })
            .test('checkCompanyEmailId', async function (value) {
                if (companyProfile.companyEmail === value)
                    return true;
                else {
                    if (value != undefined || value != '') {
                        try {
                            let result = await Axios.post("https://fludder.io/admin/WebApi/checkCompanyEmailId", {
                                companyEmail: value,
                            })
                            return result.data.type === 'error'
                                ? this.createError({
                                    message: `${result.data.message}`,
                                    path: 'companyEmail', // Fieldname
                                })
                                : true;
                        }
                        catch (error) {
                            setAlertStatus(true);
                            setType('error');
                            setTitle('Someting went worng!');
                            console.log('error : ' + error);
                            return error;
                        }
                    }
                }
            }),
        companyCurrentPassword: yup.string()
            .test('passwords-match', 'Please enter correct password', function (value) {
                if (this.parent.companyPassword !== "" && this.parent.companyPassword !== undefined)
                    return companyProfile.companyPassword === value;
                else
                    return true;
            }),
        companyPassword: yup.string()
            .max(50, 'Password should contain less than 50 characters!')
            .min(3, 'Password should contain atleast 3 characters!'),
        companyConfirmPassword: yup.string()
            .test('passwords-match', 'Passwords must match', function (value) {
                return this.parent.companyPassword === value;
            })
    })

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
            setFile(event.target.files[0]);
            setFileType(event.target.files[0].type);
        }
    }


    return (
        <>
            {/* Breadcrumb */}
            <div className="alice-bg padding-bottom-40" style={{ paddingTop: '40px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="breadcrumb-area">
                                <h1>Edit Profile</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Edit Profile</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumb End */}
            {/* Job Listing */}
            <div className="alice-bg section-padding-bottom">
                <div className="container no-gliters">
                    <div className="row no-gliters">
                        <div className="col">
                            <div className="dashboard-container">
                                <div className="dashboard-content-wrapper">
                                    <form action="#" className="dashboard-form">
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={(values, { resetForm }) => {
                                                let formData = new FormData();
                                                console.log("file : ",file);
                                                if (file) {
                                                    formData.append("companyLogo", file, file.name);
                                                }
                                                formData.append("companyName", values.companyName);
                                                formData.append("companyEmail", values.companyEmail);
                                                formData.append("companyPhoneNumber", values.companyPhoneNumber);
                                                formData.append("companyAddress", values.companyAddress);
                                                formData.append("companyAboutUs", values.companyAboutUs);
                                                if (values.companyPassword !== '')
                                                    formData.append("companyPassword", values.companyPassword);
                                                try {
                                                    Axios.request({
                                                        method: "post",
                                                        url: "https://fludder.io/admin/WebApi/updateCompanyProfile",
                                                        data: formData
                                                    })
                                                        .then((res) => {
                                                            console.log(res.data.file);
                                                            setAlertStatus(true);
                                                            setType(res.data.type);
                                                            setTitle(res.data.message);
                                                        })
                                                }
                                                catch (error) {
                                                    setAlertStatus(true);
                                                    setType('error');
                                                    setTitle('Someting went worng! Registration process not completed successfully.');
                                                    console.log('error : ' + error);
                                                    return error;
                                                }
                                                resetForm({ values: initialValues })
                                            }}
                                            validationSchema={ReviewSchema}
                                        >
                                            {(props) => (
                                                <>
                                                    <div className="dashboard-section upload-profile-photo">
                                                        <div className="update-photo">
                                                            {
                                                                image !== null ?
                                                                    <img src={image} className="image" id="imgPreview" alt='Company Logo' />
                                                                    :
                                                                    <>
                                                                        {
                                                                            companyProfile.companyLogo !== null && companyProfile.companyLogo !== '' ?
                                                                                <img className="image" src={`${process.env.REACT_APP_BASE_URL}uploads/companyLogos/` + companyProfile.companyLogo} alt="Company Logo" />
                                                                                :
                                                                                <img className="image" src="dashboard/images/defaultCompanyLogo.png" alt="Company Logo" />
                                                                        }
                                                                    </>

                                                            }

                                                        </div>
                                                        <div className="file-upload">
                                                            <input
                                                                type="file"
                                                                className="file-input"
                                                                accept='image/*'
                                                                onChange={onImageChange}
                                                            />
                                                            {companyProfile.companyLogo !== null && companyProfile.companyLogo !== '' ? 'Change Logo' : 'Add Logo'}
                                                        </div>
                                                    </div>
                                                    <div className="dashboard-section basic-info-input">
                                                        <h4>
                                                            <FeatherIcon icon="user-check" />
                                                            Basic Info
                                                        </h4>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 col-form-label">Employer Name</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Employer Name"
                                                                    onChange={props.handleChange('companyName')}
                                                                    value={props.values.companyName}
                                                                    onBlur={props.handleBlur('companyName')}
                                                                />
                                                                <p style={{ color: 'red' }}>{props.touched.companyName && props.errors.companyName}</p>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 col-form-label">Email Address</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Email Address"
                                                                    value={props.values.companyEmail}
                                                                    onChange={props.handleChange('companyEmail')}
                                                                    onBlur={props.handleBlur('companyEmail')}
                                                                />
                                                                <p style={{ color: 'red' }}>{props.touched.companyEmail && props.errors.companyEmail}</p>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 col-form-label">Phone</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Phone Number"
                                                                    value={props.values.companyPhoneNumber}
                                                                    onChange={props.handleChange('companyPhoneNumber')}
                                                                    onBlur={props.handleBlur('companyPhoneNumber')}
                                                                />
                                                                <p style={{ color: 'red' }}>{props.touched.companyPhoneNumber && props.errors.companyPhoneNumber}</p>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 col-form-label">Address</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Address"
                                                                    value={props.values.companyAddress}
                                                                    onChange={props.handleChange('companyAddress')}
                                                                    onBlur={props.handleBlur('companyAddress')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 col-form-label">About Us</label>
                                                            <div className="col-sm-9">
                                                                <textarea
                                                                    className="form-control"
                                                                    placeholder="Enter About Us"
                                                                    onChange={props.handleChange('companyAboutUs')}
                                                                    onBlur={props.handleBlur('companyAboutUs')}
                                                                    defaultValue={props.values.companyAboutUs}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard-section basic-info-input">
                                                        <h4><i data-feather="lock"></i>Change Password</h4>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 col-form-label">Current Password</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="password"
                                                                    className="form-control"
                                                                    placeholder="Current Password"
                                                                    onChange={props.handleChange('companyCurrentPassword')}
                                                                    value={props.values.companyCurrentPassword}
                                                                    onBlur={props.handleBlur('companyCurrentPassword')}
                                                                />
                                                                <p style={{ color: 'red' }}>{props.touched.companyCurrentPassword && props.errors.companyCurrentPassword}</p>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 col-form-label">New Password</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="password"
                                                                    className="form-control"
                                                                    placeholder="New Password"
                                                                    onChange={props.handleChange('companyPassword')}
                                                                    value={props.values.companyPassword}
                                                                    onBlur={props.handleBlur('companyPassword')}
                                                                />
                                                                <p style={{ color: 'red' }}>{props.touched.companyPassword && props.errors.companyPassword}</p>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 col-form-label">Retype Password</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="password"
                                                                    className="form-control"
                                                                    placeholder="Retype Password"
                                                                    onChange={props.handleChange('companyConfirmPassword')}
                                                                    value={props.values.companyConfirmPassword}
                                                                    onBlur={props.handleBlur('companyConfirmPassword')}
                                                                />
                                                                <p style={{ color: 'red' }}>{props.touched.companyConfirmPassword && props.errors.companyConfirmPassword}</p>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 col-form-label"></label>
                                                            <div className="col-sm-9">
                                                                <button
                                                                    type='submit'
                                                                    className="button"
                                                                    onClick={props.handleSubmit}
                                                                >
                                                                    Save Change
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </Formik>
                                    </form>
                                </div>
                                <EmployerLeftSideBar employerName={companyProfile.companyName} activeMenu="editProfile" companyProfile={companyProfile} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Job Listing End */}
            {/* Optional JavaScript */}
            {/* jQuery first, then Popper.js, then Bootstrap JS */}
            <ReactJsAlert
                status={alertStatus} // true or false
                type={type} // success, warning, error, info
                title={title}
                autoCloseIn='30000'
                Close={() => {setAlertStatus(false); navigate("/EmployerProfile") }}
            />
        </>
    )
};

export default EditEmployerProfile;