import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/themify-icons.css';
import './assets/css/et-line.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/plyr.css';
import './assets/css/flag.css';
import './assets/css/slick.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery.nstSlider.min.css';
import './css/main.css';
import './assets/css/dashboard.css';
import Header from './Header';
import EmployerLeftSideBar from './EmployerLeftSideBar';
import FeatherIcon from 'feather-icons-react';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from './components/Pagination';
import ReactJsAlert from "reactjs-alert";


const FavoriteCandidates = () => {
    let companyProfile = useLocation().state;
    const [favoriteUsers, setFavoriteUsers] = useState([]);
    const [RecordsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);
    const [alertStatus, setAlertStatus] = useState(false);
    const [type, setType] = useState("success");
    const [title, setTitle] = useState("");
    useEffect(() => {
        const jsonObject = {
            companyId: localStorage.getItem('companyId')
        }
        Axios.post("https://fludder.io/admin/WebApi/getCompanyFavoriteUsers",
            JSON.stringify(jsonObject)
        )
            .then((res) => {
                console.log(res.data);
                setFavoriteUsers(res.data);
            })
    }, []);
    const indexOfLast = currentPage * RecordsPerPage;
    const indexOfFirst = indexOfLast - RecordsPerPage;
    const currentRecords = favoriteUsers.slice(indexOfFirst, indexOfLast);
    //Change page
    const paginate = pageNumber => { setCurrentPage(pageNumber) };

    const removeFromFavorite = (user_information_id) => {
        const jsonobj = {
            companyId: localStorage.getItem('companyId'),
            user_information_id: user_information_id
        }
        try {
            Axios.post("https://fludder.io/admin/WebApi/removeFromFavorite",
                JSON.stringify(jsonobj)
            )
                .then((res) => {
                    setAlertStatus(true);
                    setType(res.data.type);
                    setTitle(res.data.message);
                })
        }
        catch (error) {
            setAlertStatus(true);
            setType('error');
            setTitle('Someting went worng!');
            console.log('error : ' + error);
            return error;
        }
    }

    return (
        <>
            {/* Breadcrumb */}
            <div className="alice-bg padding-bottom-40" style={{ paddingTop: '40px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="breadcrumb-area">
                                <h1>Favorite Candidates</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item active" aria-current="page">Favorite Candidates</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumb End */}
            {/* Job Listing */}
            <div className="alice-bg section-padding-bottom">
                <div className="container no-gliters">
                    <div className="row no-gliters">
                        <div className="col">
                            <div className="dashboard-container">
                                <div className="dashboard-content-wrapper">
                                    <div className="manage-candidate-container">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Candidates</th>
                                                    <th>Job Description</th>
                                                    <th>Status</th>
                                                    <th className="action">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    currentRecords.map((singleFavoriteUser, i) => {
                                                        return (
                                                            <tr className="candidates-list" key={i}>
                                                                <td className="title">
                                                                    <div className="thumb">
                                                                        {
                                                                            singleFavoriteUser.user_profile_picture !== null && singleFavoriteUser.user_profile_picture !== '' ?
                                                                                <img src={`${process.env.REACT_APP_BASE_URL}uploads/profile_pictures/` + singleFavoriteUser.user_profile_picture} className="img-fluid" alt="Profile Picture" />
                                                                                // <img src={"../admin/uploads/profile_pictures/" + singleFavoriteUser.user_profile_picture} className="img-fluid" alt="Profile Picture" />
                                                                                :
                                                                                <img src="../admin/uploads/profile_pictures/default.gif" className="img-fluid" alt="Profile Picture" />
                                                                        }
                                                                    </div>
                                                                    <div className="body">
                                                                        <h5><a href="#">{singleFavoriteUser.user_name}</a></h5>
                                                                        <div className="info">
                                                                            <span className="designation"><FeatherIcon icon="map-pin" />{singleFavoriteUser.user_city}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="status">{singleFavoriteUser.jobTitle !== null ? singleFavoriteUser.jobTitle : "NA" }</td>
                                                                <td className="status">
                                                                    {
                                                                        singleFavoriteUser.offerSentUsers !== null && singleFavoriteUser.offerSentUsers !== '' ?
                                                                            singleFavoriteUser.offerSentUsers.split(',').find(singleFavoriteUser.companyId) ?
                                                                                <>
                                                                                    <FeatherIcon icon="check-circle" />
                                                                                    Offer Sent
                                                                                </>
                                                                                :
                                                                                'Offer Not Sent'
                                                                            :
                                                                            'Offer Not Sent'
                                                                    }
                                                                </td>
                                                                <td className="action">
                                                                    <button
                                                                        type='button'
                                                                        style={{ outline: "none", background: "transparent", border: "1px solid transparent" }}
                                                                        className="download"
                                                                        title="Marked as hired"
                                                                    >
                                                                        <FeatherIcon icon="download" size={18} />
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        style={{ outline: "none", background: "transparent", border: "1px solid transparent" }}
                                                                        className="inbox"
                                                                        title="Send Message"
                                                                    >
                                                                        <FeatherIcon icon="mail" size={18} />
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        onClick={() => removeFromFavorite(singleFavoriteUser.user_information_id)}
                                                                        className="remove"
                                                                        title="Remove from favorites"
                                                                        style={{ outline: "none", background: "transparent", border: "1px solid transparent" }}
                                                                    >
                                                                        <FeatherIcon icon="trash-2" size={18} />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            favoriteUsers.length > 3 ?
                                                <Pagination
                                                    candidateVideosPerPage={RecordsPerPage}
                                                    totalCandidateVideos={favoriteUsers.length}
                                                    paginate={paginate}
                                                    currentPage={currentPage}
                                                />
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                <EmployerLeftSideBar employerName="DSHG Sonic LLC" activeMenu="favoriteCandidates" companyProfile={companyProfile} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactJsAlert
                status={alertStatus} // true or false
                type={type} // success, warning, error, info
                title={title}
                autoCloseIn='30000'
                Close={() => setAlertStatus(false)}
            />
            {/* Job Listing End */}
            {/* Optional JavaScript */}
            {/* jQuery first, then Popper.js, then Bootstrap JS */}
        </>
    )
};

export default FavoriteCandidates;