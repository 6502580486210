import React from "react";
import * as Icon from 'react-feather';
import HoverVideoPlayer from 'react-hover-video-player';

const CandidateVideos = ({ candidateVideos, loading }) => {
    if (loading)
        return <h2>Loading...</h2>
    return (
        <div className="row mb-3">
            {candidateVideos.map((candidateVideo, i) => {
                return (
                    <div className="col-12 col-lg-4 col-md-6">
                    <div className="candidate-profile my-3 col-12 candidate half-grid-candidateProfile" key={i}
                        style={{ width: '100%', backgroundColor: 'white', boxShadow: '0px 5px 20px 0px rgb(0 0 0 / 50%)', borderRadius: '10px' }}>
                        <div className="feature-thumb " style={{ position: 'relative' }}>
                            <a href="#" data-toggle="modal" data-target={"#playVideo" + candidateVideo.user_answer_and_review_id}>
                                <HoverVideoPlayer
                                    videoSrc={'https://videocompressionoutputbucket.s3.us-east-2.amazonaws.com/assets/ddd/MP4/' + candidateVideo.user_answer_video.substr(0, candidateVideo.user_answer_video.lastIndexOf('.')) + '.mp4'}
                                    style={{ cursor: 'pointer' }}
                                    videoStyle={{ borderRadius: '15px', height: '50%', minHeight: '100px', marginLeft: 'auto', width: '90%', marginRight: 'auto' }}
                                    // controlsList="nodownload nofullscreen noprogressbar"
                                    loadingOverlay={
                                        <div className="loading-overlay">
                                            <div className="loading-spinner" />
                                        </div>
                                    }
                                />
                            </a>
                        </div>
                        <div className="body" >
                            <div className="content  " style={{}}>
                                <div className="info mx-1">
                                    <span className="work-post">
                                        <a href="">
                                            <Icon.CheckSquare />
                                            {candidateVideo.domain_name}
                                        </a>
                                    </span>
                                </div>
                                <h4 className="info">
                                    <a href="">{candidateVideo.question}</a>
                                </h4>
                            </div>
                        </div>
                    </div>
                    </div>
                )
            })}
        </div>
    )
}

export default CandidateVideos;