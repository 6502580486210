import { useState } from "react";

const Pagination = ({candidateVideosPerPage, totalCandidateVideos, paginate, currentPage}) => {
    const pageNumbers = [];
    for(let i=1; i<=Math.ceil(totalCandidateVideos/candidateVideosPerPage); i++) {
        pageNumbers.push(i);
    }

    const [startIndex, setStartIndex] = useState(1);
    return(
        <>
        {/* <nav>
            <ul className="pagination">
                {pageNumbers.map(number => (
                    <li key={number} className="page-item">
                        <a href="" onClick={(e) => {e.preventDefault(); paginate(number)}} className="page-link">
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </nav> */}
        <div className="pagination-list text-center">
        <nav className="navigation pagination">
          <div className="nav-links">
            {
                currentPage !==1 ?
                <a className="prev page-numbers" href="#"  onClick={(e) => {e.preventDefault(); paginate(currentPage-1)}}><i className="fas fa-angle-left" /></a>
                :
                <a className="prev page-numbers" style={{pointerEvents: "none"}} href="#" ><i className="fas fa-angle-left" /></a>
            }
            
            {/* <a className="page-numbers current" href="#">1</a> */}
            {/* <span aria-current="page" className="page-numbers">2</span> */}
            {pageNumbers.map((number, i) => (

                number < currentPage + 5 && number > currentPage-2 ?
                    currentPage !== number ?
                        <a href="" className="page-numbers" key={i} onClick={(e) => {e.preventDefault(); paginate(number)}}>{number}</a>
                        :

                        <span aria-current="page" key={i} className="page-numbers current">{number}</span>
                :
                null
            ))}
            {/* <a className="page-numbers" href="#">4</a> */}
            {
                currentPage!==Math.ceil(totalCandidateVideos/candidateVideosPerPage) ?
                <a className="next page-numbers" href="#"  onClick={(e) => {e.preventDefault(); paginate(currentPage+1)}}><i className="fas fa-angle-right" /></a>
                :
                <a className="next page-numbers" href="#" style={{pointerEvents: "none"}} ><i className="fas fa-angle-right" /></a>
            }
            
          </div>
        </nav>
      </div>
      </>
    )
}
export default Pagination;