import React, { useState } from "react";
import { useEffect } from "react";
import { Target } from "react-feather";
import "../src/assets/css/chat.css";
import { chatkitty } from "./chatkitty/chatInstance";
import Axios from 'axios';
import { useRef } from "react";
import { firebase } from "./firebase";

export default function ChatComponent(props) {

  console.log(props);
  // End Chat session if it's not ended for some reason
  chatkitty.endSession();

  const latestMessage = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [companyProfile, setCompanyProfile] = useState();
  let channel = {};
  let session = {};
  const [isChannelCreated, setIsChannelCreated] = useState(false);

  useEffect(() => { // Get the employer details
    const jsonObject = { companyId: localStorage.getItem('companyId') };
    Axios.post("https://fludder.io/admin/WebApi/getEmployerProfileById",
      JSON.stringify(jsonObject)
    )
      .then((res) => {
        setCompanyProfile(res.data.companyProfile);
        setIsLoading(false);
      })
  }, []);

  useEffect(async () => {
    if (!isLoading) { // contiune if we have company profile details
      const email = companyProfile.companyEmail;
      const password = companyProfile.companyPassword;
      AuthWithFirebase(email, password); // first check user is exist or not and then sign in or create and sign in
    }
  }, [isLoading]);

  // authenticate with firebase
  const AuthWithFirebase = async (email, password) => {
    await firebase.auth().signInWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        await userCredential.user.getIdToken() // first get idToken
          .then(async (idToken) => {
            startSession(email, password);
          })
          .catch((error) => console.log('error in getting id token', error));
      }).catch((error) => {
        firebase.auth().createUserWithEmailAndPassword(email, password)
          .then((userCredential) => {
            startSession(email, password);// Signed in and start the session
          })
          .catch((error) => {
            console.log('Error in creating user', error);
          });
      });
  }

  // Start a session
  const startSession = async (email, password) => {
    const result = await chatkitty.startSession({ // Starting a user session with firebase auth
      username: email,
      isGuest : true,
      // authParams: {
      //   password: password,
      // },
    });

    if (result.succeeded) {
      session = result.session; // Handle session
      console.log("Session created", session);
      createDirectChannel(email); // call to create a direct channel
    }

    if (result.failed) {
      const error = result.error; // Handle error
      console.log("Error in starting session", error);
    }
  }

  // Create Direct Channel
  const createDirectChannel = async (email) => {
    const result = await chatkitty.createChannel({
      type: "DIRECT",
      members: [{ username: props.candidate.email_id }, { username: email }],
    });

    if (result.succeeded) {
      channel = result.channel; // Handle channel
      setIsChannelCreated(true);

      const listOfMessages = await chatkitty.listMessages({
        channel: channel,
      });

      if (listOfMessages.succeeded) {
        let messages = listOfMessages.paginator.items; // Handle messages

        messages.reverse().map((message) => {
          if (message.user.id === session.user.id) { // sender is who created the session
            let senderHtml = `<li class="clearfix">
                                <div class="message-data text-right">
                                  <span class="message-data-time">${new Date(message.createdTime).toLocaleDateString()}</span>
                                  <img
                                    src="https://bootdey.com/img/Content/avatar/avatar7.png"
                                    alt="avatar"
                                  />
                                </div>
                                <div class="message other-message float-right">
                                  ${message.body}
                                </div>
                              </li>`;

            document.querySelector('#all_messages ul').innerHTML += senderHtml;
          } else { // receiver
            let receiverHtml = `<li class="clearfix">
                                  <div class="message-data">
                                    <span class="message-data-time">
                                    ${new Date(message.createdTime).toLocaleDateString()}
                                    </span>
                                  </div>
                                  <div class="message my-message">
                                  ${message.body}
                                  </div>
                                </li>`;
            document.querySelector('#all_messages ul').innerHTML += receiverHtml;
          }
        });
      }

      if (listOfMessages.failed) {
        const error = listOfMessages.error; // Handle error
        console.log('Error in getting list of messages : ', error);
      }
    }

    if (result.failed) {
      const error = result.error; // Handle error
      RegisterAnotherUser(props.candidate.email_id, props.candidate.password);
    }
  };

  // Register another user if not registered
  const RegisterAnotherUser = async (email, password) => {
    await chatkitty.endSession();
    const result = await chatkitty.startSession({ // Starting a user session with firebase auth
      username: email,
      isGuest : true,
      // authParams: {
      //   password: password,
      // },
    });

    if (result.succeeded) {
      chatkitty.endSession();
      AuthWithFirebase(companyProfile.companyEmail, companyProfile.companyPassword);
    }

    if (result.failed) {
      const error = result.error; // Handle error
      console.log("Error in starting a another user session", error);
    }
  }

  // Send message
  const SendNewMessage = async () => {
    console.log(latestMessage.current.value);
    // if (latestMessage.current.value !== '') {
      const result = await chatkitty.sendMessage({
        channel: channel,
        body: latestMessage.current.value,
      });
      if (result.succeeded) {
        const message = result.message; // Handle message
        console.log('message is : ', message);

        console.log('latest message: ', latestMessage.current.value)
        let html = `<li class="clearfix">
                    <div class="message-data text-right">
                      <span class="message-data-time">10:10 AM, Today</span>
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar7.png"
                        alt="avatar"
                      />
                    </div>
                    <div class="message other-message float-right">
                      ${latestMessage.current.value}
                    </div>
                  </li>`;

        document.querySelector('#all_messages ul').innerHTML += html;
        latestMessage.current.value = "";
        latestMessage.current.focus();
      }

      if (result.failed) {
        const error = result.error; // Handle error
        console.log('Error in sending message : ', error);
      }
    // }

  }

  return (
    <>
      <div className="container">
        <div className="row clearfix mt-4">
          <div className="col-lg-12">
            <div className="card chat-app">
              <div id="plist" className="people-list">
                <div className="input-group input-group-lg">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search" />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
                <ul className="list-unstyled chat-list mt-2 mb-0">
                  <li className="clearfix active">
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      alt="avatar"
                    />
                    <div className="about">
                      <div className="name">
                        {props.candidate.user_name}
                      </div>
                      {/* <div className="status">
                        {" "}
                        <i className="fa fa-circle offline" /> left 7 mins ago{" "}
                      </div> */}
                    </div>
                  </li>
                </ul>
              </div>
              <div className="chat">
                <div className="chat-header clearfix">
                  <div className="row">
                    <div className="col-lg-12">
                      <a
                        className="pl-3 float-right"
                        href="#"
                        data-toggle="modal"
                        data-target="#view_info"
                      >
                        <img
                          src="https://img.icons8.com/material/48/000000/user-male-circle--v1.png"
                          alt="avatar"
                        />
                      </a>
                      <div className="chat-about">
                        <h6 className="m-b-0">
                          {props.candidate.user_name}
                        </h6>
                        <small>Last seen: 2 hours ago</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chat-history" id="all_messages">
                  <ul className="m-b-0">
                    {/* Show all messages here */}
                    {
                      !isChannelCreated ? 'Loading...' : ''
                    }
                  </ul>
                </div>
                <div className="chat-message clearfix">
                  <div className="input-group input-group-lg">
                    <input
                      className="form-control border no-shadow no-rounded"
                      placeholder="Type your message here"
                      ref={latestMessage}
                    />
                    <span className="input-group-btn">
                      <button
                        className="btn btn-success btn-lg rounded-0"
                        type="button"
                        onClick={SendNewMessage}
                        disabled={isChannelCreated ? false : true}
                      >
                        Send
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}