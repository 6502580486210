import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from 'axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ReactJsAlert from "reactjs-alert"
import FeatherIcon from 'feather-icons-react';
import { chatkitty } from "./chatkitty/chatInstance";
import { firebase } from './firebase';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const Navbar = () => {

    let navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [companyProfile, setCompanyProfile] = useState([]);
    let session = {};
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '' });

    useEffect(() => {
        if (localStorage.getItem('companyId') && localStorage.getItem('companyId') !== "") {
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            const jsonObject = { companyId: localStorage.getItem('companyId') };
            Axios.post("https://fludder.io/admin/WebApi/getEmployerProfileById",
                JSON.stringify(jsonObject)
            )
                .then((res) => {
                    setCompanyProfile(res.data.companyProfile);
                })
        }
    }, [isLoggedIn]);
    const [value, setValue] = useState()

    // End Chat session if it's not ended for some reason
    // chatkitty.endSession();

    // useEffect(async () => {
    //     if (isLoggedIn) { // contiune if we have company profile details
    //         const email = companyProfile.companyEmail;
    //         const password = companyProfile.companyPassword;

    //         AuthWithFirebase(email, password); // first check user is exist or not and then sign in or create and sign in
    //     }
    // }, [companyProfile]);

    // // authenticate with firebase
    // const AuthWithFirebase = async (email, password) => {
    //     await firebase.auth().signInWithEmailAndPassword(email, password)
    //         .then(async (userCredential) => {
    //             await userCredential.user.getIdToken() // first get idToken
    //                 .then(async (idToken) => {
    //                     startSession(email, password);
    //                 })
    //                 .catch((error) => console.log('error in getting id token', error));
    //         }).catch((error) => {
    //             firebase.auth().createUserWithEmailAndPassword(email, password)
    //                 .then((userCredential) => {
    //                     startSession(email, password);// Signed in and start the session
    //                 })
    //                 .catch((error) => {
    //                     console.log('Error in creating user', error);
    //                 });
    //         });
    // }

    // // Start a session
    // const startSession = async (email, password) => {
    //     const result = await chatkitty.startSession({ // Starting a user session with firebase auth
    //         username: email,
    //         authParams: {
    //             password: password,
    //         },
    //     });

    //     if (result.succeeded) {
    //         session = result.session; // Handle session
    //         console.log("Session created from navbar", session);
    //         // createDirectChannel(); // call to create a direct channel
    //         chatkitty.onNotificationReceived((notification) => {
    //             console.log("notification => ", notification);
    //             setNotification({ title: notification.title, body: notification.body })
    //             setShow(true);
    //         });
    //     }

    //     if (result.failed) {
    //         const error = result.error; // Handle error
    //         console.log("Error in starting session", error);
    //     }
    // }
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const ReviewSchema = yup.object({
        companyName: yup.string().required('Please enter company name').min(2, 'Company name should contain atleast 3 characters!'),
        companyEmail: yup.string().required('Please enter email id').min(3, 'Email id should contain atleast 3 characters!')
            .test(
                'isAlphabets', 'Please enter valid email id', (val) => {
                    return /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(val);
                })
            .test('checkCompanyEmailId', async function (value) {
                if (value !== undefined || value !== '') {
                    try {
                        let result = await Axios.post("https://fludder.io/admin/WebApi/checkCompanyEmailId", {
                            companyEmail: value,
                        })
                        return result.data.type === 'error'
                            ? this.createError({
                                message: `${result.data.message}`,
                                path: 'companyEmail', // Fieldname
                            })
                            : true;
                    }
                    catch (error) {
                        setAlertStatus(true);
                        setType('error');
                        setTitle('Someting went worng! Unable to process email id.');
                        console.log('error : ' + error);
                        return error;
                    }
                }
            }),
        // companyPhoneNumber: yup.string().required('Please enter phone number.').matches(phoneRegExp, 'Phone number is not valid'),
        companyPassword: yup.string().required('Please enter password.')
            .max(50, 'Password should contain less than 50 characters!')
            .min(3, 'Password should contain atleast 3 characters!'),
        companyConfirmPassword: yup.string().required('Please enter password again')
            .test('passwords-match', 'Passwords must match', function (value) {
                return this.parent.companyPassword === value;
            }),
        companyTermsAndConditions: yup.boolean()
            .oneOf([true], "You must accept the terms and conditions")
    })
    const [alertStatus, setAlertStatus] = useState(false);
    const [type, setType] = useState("success");
    const [title, setTitle] = useState("Please check your mail to activate your account.");
    const initialValues = {
        companyName: '', companyEmail: '', companyPhoneNumber: '', companyPassword: '', companyConfirmPassword: '', companyTermsAndConditions: false
    }
    const initialValuesForLogin = {
        companyEmail: '', companyPassword: ''
    }
    const ReviewSchemaForLogin = yup.object({
        companyEmail: yup.string().required('Please enter email id').min(3, 'Email id should contain atleast 3 characters!')
            .test(
                'isAlphabets', 'Please enter valid email id', (val) => {
                    return /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(val);
                }),
        companyPassword: yup.string().required('Please enter password')
            .max(50, 'Password should contain less than 50 characters!')
            .min(3, 'Password should contain atleast 3 characters!'),
    })
    const sendToHome = () => {
        window.location.href = 'https://fludder.io/candidate/'
    }


    return (<>
        <nav className="navbar navbar-expand-xl absolute-nav transparent-nav cp-nav navbar-light fluid-nav" style={{ boxShadow: '0px 3px 6px #00000022' }}>
            <NavLink className="navbar-brand" to="/">
                <img src={require("./images/logo.png")} className="img-fluid" alt="" />
            </NavLink>
            <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                {/* <span className="navbar-toggler-icon" /> */}
                <span><i className="fas fa-bars"></i></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto main-nav">
                    <li className="menu-item"  >
                        {
                            !localStorage.getItem('companyId') || localStorage.getItem('companyId') === '' ?
                                <NavLink style={{ fontWeight: 'bold' }} title="Start Hiring" to="/" data-toggle="modal" data-target="#exampleModalLong">Start Hiring</NavLink>
                                :
                                <NavLink style={{ fontWeight: 'bold' }} title="Start Hiring" to="/searchcandidates">Start Hiring</NavLink>
                        }
                    </li>
                    <li className="menu-item">
                        <NavLink style={{ fontWeight: 'bold' }} to="/pricing">Pricing</NavLink>
                    </li>
                    <li className="menu-item">
                        {/* {
                            !localStorage.getItem('companyId') || localStorage.getItem('companyId') === '' ?
                                <NavLink style={{ fontWeight:'bold' }} title="Start Hiring" to="/" data-toggle="modal" data-target="#exampleModalLong">Candidates</NavLink>
                                : */}
                        <NavLink style={{ fontWeight: 'bold' }} title="Start Hiring" to="/newcandidate">Employers</NavLink>
                        {/* } */}
                    </li>
                </ul>

                <ul className="navbar-nav ml-auto account-nav">
                    {
                        !localStorage.getItem('companyId') || localStorage.getItem('companyId') === '' ?
                            <>
                                <li className="menu-item login-popup">
                                    <button className="bg-transparent"
                                        title="Login"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#exampleModalLong"
                                    >
                                        Sign In
                                    </button>
                                </li>
                                <li className="menu-item login-popup">
                                    <button
                                        className="primary-btn"
                                        title="Sign Up"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#exampleModalLong2"
                                    >
                                        Create account
                                    </button>
                                </li>
                            </>
                            :
                            <li className="menu-item">
                                <NavLink to="/employerprofile" state={companyProfile} >
                                    <FeatherIcon icon="user" />
                                    &nbsp;My Account
                                </NavLink>
                            </li>
                    }
                </ul>

            </div>
        </nav>
        <div className="account-entry">

            {/* ################# Signup and login Modals ######################## */}

            {/* ######real */}
            <div
                className="modal fade"
                id="exampleModalLong"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <i data-feather="user" />
                                Log In
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                id="closeModelbtn"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* <form action="#"> */}
                            <Formik
                                initialValues={initialValuesForLogin}
                                onSubmit={(values, { resetForm }) => {
                                    try {
                                        Axios.post(`${process.env.REACT_APP_BASE_URL}WebApi/login`, {
                                            companyEmail: values.companyEmail,
                                            companyPassword: values.companyPassword
                                        })
                                            .then((res) => {
                                                document.getElementById("closeModelbtn").click();
                                                // setCompanyEmail(values.companyEmail);
                                                if (res.data.type === 'success') {
                                                    localStorage.setItem('companyId', res.data.companyProfile.companyId)
                                                    setIsLoggedIn(true);
                                                    setCompanyProfile(res.data.companyProfile);
                                                    setAlertStatus(true);
                                                    setType(res.data.type);
                                                    setTitle(res.data.message);
                                                    // setTimeout(() => { window.location.href = 'https://fludder.io/searchcandidates'; }, 3000);
                                                    setTimeout(() => { navigate("/searchcandidates") }, 2500);
                                                    // navigate("/searchcandidates");
                                                }
                                                else
                                                    setIsLoggedIn(false);
                                                setAlertStatus(true);
                                                setType(res.data.type);
                                                setTitle(res.data.message);
                                            })
                                    }
                                    catch (error) {
                                        setAlertStatus(true);
                                        setType('error');
                                        setTitle('Someting went worng! Login process not completed successfully.');
                                        console.log('error : ' + error);
                                        return error;
                                    }
                                    resetForm({ values: initialValuesForLogin })
                                }}
                                validationSchema={ReviewSchemaForLogin}
                            >
                                {(props) => (
                                    <div>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                placeholder="Email Address"
                                                className="form-control"
                                                onChange={props.handleChange('companyEmail')}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { props.handleSubmit() } }}
                                                value={props.values.companyEmail}
                                                onBlur={props.handleBlur('companyEmail')}
                                            />
                                            <p style={{ color: 'red', textAlign: 'center' }}>{props.touched.companyEmail && props.errors.companyEmail}</p>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                placeholder="Company Password"
                                                className="form-control"
                                                onChange={props.handleChange('companyPassword')}
                                                value={props.values.companyPassword}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { props.handleSubmit() } }}
                                                onBlur={props.handleBlur('companyPassword')}
                                            />
                                            <p style={{ color: 'red', textAlign: 'center' }}>{props.touched.companyPassword && props.errors.companyPassword}</p>
                                        </div>
                                        <button
                                            type="submit"
                                            className="button primary-bg btn-block"
                                            onClick={props.handleSubmit}
                                        >
                                            Login
                                        </button>
                                    </div>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="exampleModalLong2"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <i data-feather="edit" />
                                Sign Up
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                id="closeModalButton"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values, { resetForm }) => {
                                    try {
                                        Axios.post(`${process.env.REACT_APP_BASE_URL}WebApi/`, {
                                            companyName: values.companyName,
                                            companyEmail: values.companyEmail,
                                            companyPhoneNumber: value,
                                            companyPassword: values.companyPassword
                                        })
                                            .then((res) => {
                                                document.getElementById("closeModalButton").click();
                                                setAlertStatus(true);
                                                setType(res.data.type);
                                                setTitle(res.data.message);
                                            })
                                    }
                                    catch (error) {
                                        setAlertStatus(true);
                                        setType('error');
                                        setTitle('Someting went worng! Registration process not completed successfully.');
                                        console.log('error : ' + error);
                                        return error;
                                    }
                                    resetForm({ values: initialValues })
                                }}
                                validationSchema={ReviewSchema}
                            >
                                {(props) => (
                                    <div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Company Name"
                                                className="form-control"
                                                onChange={props.handleChange('companyName')}
                                                value={props.values.companyName}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { props.handleSubmit() } }}
                                                onBlur={props.handleBlur('companyName')}
                                            />
                                            <p style={{ color: 'red', textAlign: 'center' }}>{props.touched.companyName && props.errors.companyName}</p>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                placeholder="Email Address"
                                                className="form-control"
                                                onChange={props.handleChange('companyEmail')}
                                                value={props.values.companyEmail}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { props.handleSubmit() } }}
                                                onBlur={props.handleBlur('companyEmail')}
                                            />
                                            <p style={{ color: 'red', textAlign: 'center' }}>{props.touched.companyEmail && props.errors.companyEmail}</p>
                                        </div>
                                        <div className="form-group">
                                            {/* <input
                                                type="text"
                                                placeholder="Phone Number"
                                                className="form-control"
                                                onChange={props.handleChange('companyPhoneNumber')}
                                                value={props.values.companyPhoneNumber}
                                                onKeyDown={(e) => {if(e.key === 'Enter'){props.handleSubmit()}}}
                                                onBlur={props.handleBlur('companyPhoneNumber')}
                                            /> */}
                                            <PhoneInput
                                                defaultCountry="US"
                                                placeholder="Phone Number"
                                                value={value}
                                                onChange={setValue}
                                            // onKeyDown={(e) => {if(e.key === 'Enter'){props.handleSubmit()}}}
                                            // onBlur={props.handleBlur('companyPhoneNumber')}
                                            />
                                            <p style={{ color: 'red', textAlign: 'center' }}>{props.touched.companyPhoneNumber && props.errors.companyPhoneNumber}</p>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                placeholder="Company Password"
                                                className="form-control"
                                                onChange={props.handleChange('companyPassword')}
                                                value={props.values.companyPassword}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { props.handleSubmit() } }}
                                                onBlur={props.handleBlur('companyPassword')}
                                            />
                                            <p style={{ color: 'red', textAlign: 'center' }}>{props.touched.companyPassword && props.errors.companyPassword}</p>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                placeholder="Confirm Password"
                                                className="form-control"
                                                onKeyDown={(e) => { if (e.key === 'Enter') { props.handleSubmit() } }}
                                                onChange={props.handleChange('companyConfirmPassword')}
                                                value={props.values.companyConfirmPassword}
                                                onBlur={props.handleBlur('companyConfirmPassword')}
                                            />
                                            <p style={{ color: 'red', textAlign: 'center' }}>{props.touched.companyConfirmPassword && props.errors.companyConfirmPassword}</p>
                                        </div>
                                        <div className="more-option terms">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="defaultCheck2"
                                                    onChange={props.handleChange('companyTermsAndConditions')}
                                                    value={props.values.companyTermsAndConditions}
                                                    onKeyDown={(e) => { if (e.key === 'Enter') { props.handleSubmit() } }}
                                                    onBlur={props.handleBlur('companyTermsAndConditions')}
                                                />
                                                <label className="form-check-label" htmlFor="defaultCheck2">
                                                    I accept the <NavLink to="/termsandconditions" target="_blank">terms &amp; conditions</NavLink>
                                                </label>
                                                <p style={{ color: 'red', textAlign: 'center' }}>{props.touched.companyTermsAndConditions && props.errors.companyTermsAndConditions}</p>
                                            </div>
                                        </div>
                                        <button
                                            type="submit"
                                            className="button primary-bg btn-block"
                                            onClick={props.handleSubmit}
                                        >
                                            Register
                                        </button>
                                        <div className="shortcut-login">
                                            <p>
                                                Already have an account? <NavLink to="#">Login</NavLink>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* </header> */}
        {/* Header End */}
        <ReactJsAlert
            status={alertStatus} // true or false
            type={type} // success, warning, error, info
            title={title}
            autoCloseIn='30000'
            Close={() => setAlertStatus(false)}
        />
    </>)
}
export default Navbar;