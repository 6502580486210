import React from 'react';

const DataDeletionInstructions = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: '50px' }}>
      <h2 style={{color: "#360a65"}}>Data Deletion Instructions</h2>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <ol style={{ textAlign: 'left' }}>
          <li><strong style={{color: "#f9a132"}}>Log in to your account:</strong> Access the account associated with our app on Play Store & App Store.</li>
          <div style={{ marginTop: '10px', textAlign: 'center', marginBottom: '20px' }}>
            <img src={require("./images/dataDeletion/Login.jpeg")} alt="Step 1 Screenshot" style={{ maxWidth: '300px' }} />
          </div>
          <li><strong style={{color: "#f9a132"}}>Navigate to Profile:</strong> Once logged in, go to the profile section within the app.</li>
          <div style={{ marginTop: '10px', textAlign: 'center', marginBottom: '20px' }}>
            <img src={require("./images/dataDeletion/Profile.jpeg")} alt="Step 2 Screenshot" style={{ maxWidth: '300px' }} />
          </div>
          <li><strong style={{color: "#f9a132"}}>Click on Delete Accont button:</strong> Look for the button to delete account and click on that.</li>
          <div style={{ marginTop: '10px', textAlign: 'center', marginBottom: '20px' }}>
            <img src={require("./images/dataDeletion/Profile.jpeg")} alt="Step 3 Screenshot" style={{ maxWidth: '300px' }} />
          </div>
          <li><strong style={{color: "#f9a132"}}>Confirm your deletion:</strong> Once you click on Delete Accont button you will get confirmation box to confirm that you want to delete your account. <br/>Click on Delete button in confirmation box and your account will get deleted completely.</li>
          <div style={{ marginTop: '10px', textAlign: 'center', marginBottom: '20px' }}>
            <img src={require("./images/dataDeletion/DeleteAccount.jpeg")} alt="Step 4 Screenshot" style={{ maxWidth: '300px' }} />
          </div>
        </ol>
        <p>If you encounter any issues or have questions regarding data deletion, please feel free to contact our support team at <a href="mailto:info@fludder.io">info@fludder.io</a>.</p>
        <p style={{marginBottom: '20px'}}>Thank you for using our app. We are committed to maintaining the privacy and security of your data.</p>
      </div>
    </div>
  );
};

export default DataDeletionInstructions;
