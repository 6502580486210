import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/themify-icons.css';
import './assets/css/et-line.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/plyr.css';
import './assets/css/flag.css';
import './assets/css/slick.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery.nstSlider.min.css';
import './css/main.css';
import { NavLink } from 'react-router-dom';
import Navbar from './Navbar';
import * as Icon from 'react-feather';
import HoverVideoPlayer from 'react-hover-video-player';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import Multiselect from 'multiselect-react-dropdown';

const Home = () => {
  const [candidates, setCandidates] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [selectedList, setSelectedList] = useState();
  const [zipCode, setZipCode] = useState();
  const [options, setOptions] = useState([]);
  const [companyProfile, setCompanyProfile] = useState([]);
  const onSelect = (selectedList, selectedItem) => {
    setSelectedList(selectedList);
  }

  const onRemove = (selectedList, removedItem) => {
    setSelectedList(selectedList);
  }
  const multiSelectStyle = {
    searchBox: {
      border: "none",
      borderBottom: '2px solid rgb(0,0,0,0)',
      width: '100%'

    },
    multiselectContainer: {
      width: '300px'
    },
    inputField: { // To change input field position or margin
      // marginTop: '0px',

      width: '100%',

    },
    width: '100%'

  };
  useEffect(() => {
    console.log("localStorage : ", localStorage.companyId);
    const newJson = { companyId: localStorage.companyId }
    Axios.post("https://fludder.io/admin/WebApi/getCategoriesAndJobTitles", newJson)
      .then((res) => {
        setOptions(res.data);
      })
    Axios.post("https://fludder.io/admin/WebApi/getTopFourCandidates")
      .then((res) => {
        setCandidates(res.data);
      })

  }, []);

  useEffect(() => {
    if (localStorage.getItem('companyId') && localStorage.getItem('companyId') !== "") {
      setIsLoggedIn(true);
      setCompanyId(localStorage.getItem('companyId'))
    }
  }, []);

  const sendToSearch = () => {
    window.location.href = '/searchcandidates/'
  }

  const getCandidateName = (candidateName) => {
    let firstname = candidateName.substr(0, candidateName.indexOf(' '));
    let lastname = (candidateName.substr(candidateName.indexOf(' ') + 1).trim()).charAt(0);
    return firstname + ' ' + lastname;
  }

  return (
    <>
      {/* Banner */}
      <div className="banner banner-1 banner-1-bg ">
        <div className="banner-width">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-12 gradiant-banner">
              <div className="banner-content">
                <h2 style={{ color: '#FFFFFF' }}>Find your ideal <br /> candidate today.</h2>
                <p style={{ color: 'rgb(255 255 255 / 80%)', fontSize: '20px' }}>Why wait for candidates to come to<br />you? Explore our database of entry- <br />level talent and start hiring right away</p>
                {
                  isLoggedIn ?
                    <NavLink
                      to={"/searchcandidates"}
                      style={{ color: '#fff' }}
                      className="banner-btn"
                    >
                      Find Your Ideal Candidate &nbsp;
                    </NavLink>
                    :
                    <NavLink to="/" style={{ color: '#fff' }} className="banner-btn" data-toggle="modal" data-target="#exampleModalLong">
                      Find Your Ideal Candidate &nbsp;
                    </NavLink>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Banner End */}
      {/* Search and Filter */}
      <div className="">
        <div className="">
          <div className="row">
            <div className="col px-0" style={{ boxShadow: '0px 2px 5px lightgrey' }}>
              <div className="">
                <div className="searchAndFilter ml-lg-5 px-0 ml-1" style={{ textAlign: 'center' }}>
                  <form action="#" className="search-form  d-flex justify-content-around ml-md-5 ml-1 ">
                    {/* <input type="text" placeholder="Job Description you are interested in" /> */}
                    <div className='row d-flex justify-content-md-around justify-content-start flex-wrap col-xl-10 col-12 ' >

                      <div className='col-lg-4  col-md-4 col-10 px-0 d-flex mt-md-auto h-md-100 d-flex' style={{ borderBottom: '2px solid #B5B5B5', width: '100%' }}>
                        <Multiselect
                          options={options.map((singleOption, i) => { return ({ name: singleOption.jobTitle, id: i }) })} // Options to display in the dropdown
                          // selectedValues={selectedValue} // Preselected value to persist in dropdown
                          onSelect={onSelect} // Function will trigger on select event
                          onRemove={onRemove} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                          placeholder="Position you are looking for ?"
                          style={multiSelectStyle}
                          className='mx-auto'
                        />
                      </div>
                      <div className='col-lg-4 col-md-4 h-md-100 px-0  col-10 d-flex' style={{ borderBottom: '2px solid #B5B5B5', width: '100%' }}>
                        <input
                          type="text"
                          className='mt-md-auto '
                          placeholder="Enter ZIP Code"
                          style={{
                            borderBottom: '2px solid rgb(0,0,0,0)',
                            width: '100%'
                          }}
                          onChange={(e) => { setZipCode(e.target.value) }}
                        />
                      </div>
                      <div className='col-lg-3 col-md-3 col-10 h-md-100 d-flex  ' style={{ width: '100%' }}>
                        <div className='mt-md-auto mt-3 '>
                          {
                            isLoggedIn ?
                              <NavLink to="/searchcandidates" state={zipCode} className="banner-btn custombuttonpadding">
                                {/* <i className="fas fa-search" /> */}
                                <Icon.Search size={20} />
                                &nbsp;Find Candidates
                              </NavLink>
                              :
                              <NavLink to="/" className="banner-btn custombuttonpadding" data-toggle="modal" data-target="#exampleModalLong">
                                <Icon.Search />
                                &nbsp;Find Candidates
                              </NavLink>
                          }
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Search and Filter End */}
      {/* Jobs */}
      <div className="alice-bg">
        <div className='home-candidate-circle-1'>
          <div className='home-candidate-circle-2'>
            <div className="banner-width">
              <div className="row  searchAndFilter  px-md-5 px-0"> {/* style={{ padding: "49px 96px 49px 96px" }} */}
                <div className='row  d-flex justify-content-center mx-md-5'>
                  {candidates.slice(0, 3).map((candidate, i) => {
                    return (
                      <div className='  col-xl-3 px-0 my-3 col-lg-5 col-sm-7 col-12'>
                        <div className='mx-3'>
                          <div className="homepage candidate half-grid-home w-100 " key={i}
                            style={{ backgroundColor: 'white', boxShadow: '0px 3px 6px #00000029', borderRadius: '23px', height: '100%' }}>
                            <div className="feature-thumb" style={{ position: 'relative' }}>
                              <HoverVideoPlayer
                                videoSrc={'https://videocompressionoutputbucket.s3.us-east-2.amazonaws.com/assets/ddd/MP4/' + candidate.user_answer_video.substr(0, candidate.user_answer_video.lastIndexOf('.')) + '.mp4'}
                                style={{ width: '90%', height: '100%' }}
                                sizingMode='video'
                                videoStyle={{ borderRadius: '15px', height: '190px' }}
                                controlsList="nodownload nofullscreen noprogressbar"
                                loadingOverlay={
                                  <div className="loading-overlay">
                                    {/* <div className="loading-spinner" /> */}
                                    Loading...
                                  </div>
                                }
                                // preload="metadata"
                                crossOrigin="anonymous"
                              />
                            </div>
                            <div className="body" style={{ minHeight: 'auto', }}>
                              <div className="content">
                                <h4 style={{ color: '#301261', fontWeight: '700' }}>
                                  {getCandidateName(candidate.user_name)}
                                </h4>
                                <div className="info " >
                                  {
                                    candidate.user_city ?
                                      <span className="location  locationhover mx-3" style={{ textOverflow: 'ellipsis' }} >
                                        <svg id="pin" xmlns="http://www.w3.org/2000/svg" width="22.186" height="29.697" viewBox="0 0 22.186 29.697">
                                          <path id="Path_6" data-name="Path 6" d="M21.083,13.746a4.338,4.338,0,1,1-4.338-4.338,4.338,4.338,0,0,1,4.338,4.338Zm-2.169,0a2.169,2.169,0,1,1-2.169-2.169,2.169,2.169,0,0,1,2.169,2.169Z" transform="translate(-5.285 -1.333)" fill-rule="evenodd" />
                                          <path id="Path_7" data-name="Path 7" d="M7.944,22.713A13.269,13.269,0,0,1,7.559,5.319a10.34,10.34,0,0,1,15.683-.427,13.269,13.269,0,0,1,.384,17.394L15.977,31.2Zm13.9-2.312-5.949,6.931-6.249-6.6a10.321,10.321,0,0,1-.3-13.528,8.043,8.043,0,0,1,12.2-.332,10.321,10.321,0,0,1,.3,13.528Z" transform="translate(-4.5 -1.5)" fill-rule="evenodd" />
                                        </svg>

                                        {candidate.user_city}

                                      </span>
                                      :
                                      null
                                  }
                                  {
                                    candidate.user_description ?
                                      <div style={{ margin: '0px 60px 10px' }}>
                                        <span className="work-post">
                                          <p className=' text-ellipsis--2 '>
                                            {candidate.user_description.substr(0, 33)}...
                                          </p>
                                        </span>
                                      </div>
                                      :
                                      null
                                  }
                                </div>
                              </div>
                              <div className="row" style={{ justifyContent: "center" }}>
                                <div className="card-btn fw-bold" style={{ marginLeft: '2px', marginRight: '2px', boxShadow: '0px 2px 5px lightgrey' }}>
                                  {
                                    isLoggedIn ?
                                      <NavLink state={{ candidate: candidate }} to="/candidateprofile">View Profile</NavLink>
                                      :
                                      <NavLink to="/" data-toggle="modal" data-target="#exampleModalLong">View Profile</NavLink>
                                  }

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                  <div className="  col-xl-3 px-0 mx-0 col-lg-5 col-sm-7 col-12 d-flex justify-content-center align-items-center mt-lg-0 mt-md-5 mt-sm-4 mt-4">
                    <div className='mx-5'>
                      <div className="">
                        <h4 style={{ color: '#240749', }}><span style={{ fontWeight: '800', fontSize: '30px', fontFamily: 'Poppins' }}>See how candidates answer real interview questions.</span></h4>
                      </div>
                      <div className="mt-5" >
                        {
                          isLoggedIn ?
                            <NavLink style={{ boxShadow: '0px 2px 5px lightgrey' }} to="/searchcandidates" className="card-btn fw-bold">
                              View more candidates
                            </NavLink>
                            :
                            <NavLink style={{ boxShadow: '0px 2px 5px lightgrey' }} to="/" data-toggle="modal" data-target="#exampleModalLong" className="card-btn fw-bold">
                              View more candidates
                            </NavLink>
                        }
                      </div>
                    </div>
                  </div>

                </div>

                <div className="apply-popup">
                  <div
                    className="modal fade"
                    id="apply-popup-id"
                    tabIndex={-1}
                    role="dialog"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <i data-feather="edit" />
                            APPLY FOR THIS JOB
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form action="#">
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Full Name"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="email"
                                placeholder="Email Address"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                placeholder="Message"
                                defaultValue={""}
                              />
                            </div>
                            <div className="form-group file-input-wrap">
                              <label htmlFor="up-cv">
                                <input id="up-cv" type="file" />
                                <i data-feather="upload-cloud" />
                                <span>
                                  Upload your resume <span>(pdf,zip,doc,docx)</span>
                                </span>
                              </label>
                            </div>
                            <div className="more-option">
                              <input
                                className="custom-radio"
                                type="checkbox"
                                id="radio-4"
                                name="termsandcondition"
                              />
                              <label htmlFor="radio-4">
                                <span className="dot" /> I accept the{" "}
                                <a href="https://acedmyinterview.com/privacy-policy/" target="_blank">terms &amp; conditions</a>
                              </label>
                            </div>
                            <button className="button primary-bg btn-block">
                              Apply Now
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Jobs End */}

      <div className="section-padding-top padding-bottom">
        <div className='home-feature-circle-1'>
          <div className='home-feature-circle-2'>
            <div className='home-feature-circle-3'>
              <div className='home-feature-circle-4'>
                <div className="hiring-section">
                  <div className="row">
                    <div className="col">
                      <div className="">
                        <h4 style={{ textAlign: 'center', color: '#240749' }}><span style={{ fontWeight: '700', fontFamily: 'Poppins' }}>Fludder Makes Hiring Easy</span></h4>
                        <div className="about-company">
                          <div className="row d-flex justify-content-center">
                            <div className="col-lg-5 order-lg-2">
                              <div className="feature-thumb">
                                <img
                                  src={require("./images/feature/home-feature-1.png")}
                                  className="img-fluid"
                                  alt=""
                                  style={{ maxWidth: '90%' }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-5  order-lg-1 d-flex align-items-center pt-sm-3">
                              <div>
                                <h4 className='px-5' style={{ color: '#240749' }}><span style={{ fontWeight: '700', fontFamily: 'Poppins' }}>Search</span></h4>
                                <div className='mt-3 px-5' style={{ fontSize: '23px', color: '#585858', lineHeight: '1.3' }}>
                                  <p style={{ fontSize: '17px', fontWeight: '400', fontFamily: 'Poppins' }}>
                                    Search for your ideal candidate immediately using our custom filters to review candidate videos and profiles.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex justify-content-center">
                            <div className="col-lg-5 order-lg-1">
                              <div className="feature-thumb" style={{ textAlign: 'center' }}>
                                <img
                                  src={require("./images/feature/connect@2x.png")}
                                  className="img-fluid"
                                  alt=""
                                  style={{ maxWidth: '80%' }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-5 order-lg-2 d-flex align-items-center pt-sm-3">
                              <div>
                                <h4 className='px-5' style={{ color: '#240749' }}><span style={{ fontWeight: '700', fontFamily: 'Poppins' }}>Connect</span></h4>
                                <div className='mt-3 px-5' style={{ fontSize: '20px', color: '#585858', lineHeight: '1.3' }}>
                                  <p style={{ fontSize: '17px', fontWeight: '400', fontFamily: 'Poppins' }}>
                                    Connect with candidates and interview them from one platform using our messaging system and interview portal.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex justify-content-center">
                            <div className="col-lg-5 order-lg-2">
                              <div className="feature-thumb" style={{ textAlign: 'center' }}>
                                <img
                                  src={require("./images/feature/hire@2x.png")}
                                  className="img-fluid"
                                  alt=""
                                  style={{ maxWidth: '90%' }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-5 order-lg-1 d-flex align-items-center pt-sm-3">
                              <div>
                                <h4 className='px-5' style={{ color: '#240749' }}><span style={{ fontWeight: '700', fontFamily: 'Poppins' }}>Hire</span></h4>
                                <div className='px-5' style={{ fontSize: '20px', color: '#585858' }}>
                                  <p style={{ fontSize: '17px', fontWeight: '400', fontFamily: 'Poppins' }}>
                                    Make an offer and hire directly through our platform.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Candidates Details */}
      <div className="padding-top-30 padding-bottom-30 fact-bg">
        <div className="banner-width ytb-section">
          <div className="row fact-items">
            <div className="col-md-6 col-sm-12  px-md-0" style={{ padding: '5px' }}>
              <h4 className="padding-bottom-40 pt-5" style={{ color: '#240749', fontWeight: 'bolder' }}>
                <span style={{ fontWeight: '700', fontFamily: 'Poppins' }}>
                  Fludder is the fastest way <br />to find your perfect candidate
                </span>
              </h4>
              <div className="fact row " style={{ flexWrap: 'nowrap' }}>
                <div className="fact-icon ">
                  <svg id="check" xmlns="http://www.w3.org/2000/svg" width="22" height="27.75" viewBox="0 0 33.614 27.75">
                    <path id="check-2" data-name="check" d="M14,32.063l-.519-.528-.277-.274L7.14,25.2l0,0L1.261,19.313,8.124,12.45,14,18.326,28.012,4.313l6.863,6.864L20.864,25.188l-.792.8Zm-3.689-6.878L14,28.874,14,28.867l0,0,3.678-3.678.792-.8L31.694,11.178,28.013,7.5,14,21.508,8.126,15.632,4.445,19.313l5.869,5.868Z" transform="translate(-1.261 -4.313)" />
                  </svg>
                </div>
                <p className="fact-number mx-md-4" style={{ textAlign: 'start' }}>
                  <span style={{ fontSize: '18px', color: '#5B5B5B' }}>No Job Descriptions</span>
                </p>
              </div>
              <div className="fact row " style={{ flexWrap: 'nowrap' }}>
                <div className="fact-icon">
                  <svg id="check" xmlns="http://www.w3.org/2000/svg" width="22" height="27.75" viewBox="0 0 33.614 27.75">
                    <path id="check-2" data-name="check" d="M14,32.063l-.519-.528-.277-.274L7.14,25.2l0,0L1.261,19.313,8.124,12.45,14,18.326,28.012,4.313l6.863,6.864L20.864,25.188l-.792.8Zm-3.689-6.878L14,28.874,14,28.867l0,0,3.678-3.678.792-.8L31.694,11.178,28.013,7.5,14,21.508,8.126,15.632,4.445,19.313l5.869,5.868Z" transform="translate(-1.261 -4.313)" />
                  </svg>
                </div>
                <p className="fact-number mx-md-4" style={{ textAlign: 'start' }}>
                  <span style={{ fontSize: '18px', color: '#5B5B5B' }}>No One-Click Apply</span>
                </p>
              </div>
              <div className="fact row " style={{ flexWrap: 'nowrap' }}>
                <div className="fact-icon">
                  <svg id="check" xmlns="http://www.w3.org/2000/svg" width="22" height="27.75" viewBox="0 0 33.614 27.75">
                    <path id="check-2" data-name="check" d="M14,32.063l-.519-.528-.277-.274L7.14,25.2l0,0L1.261,19.313,8.124,12.45,14,18.326,28.012,4.313l6.863,6.864L20.864,25.188l-.792.8Zm-3.689-6.878L14,28.874,14,28.867l0,0,3.678-3.678.792-.8L31.694,11.178,28.013,7.5,14,21.508,8.126,15.632,4.445,19.313l5.869,5.868Z" transform="translate(-1.261 -4.313)" />
                  </svg>
                </div>
                <p className="fact-number   mx-md-4 px-0 " style={{ textAlign: 'start' }}>
                  <span className='px-0' style={{ fontSize: '18px', color: '#5B5B5B' }}>No More Paying for the Wrong Candidates</span>
                </p>
              </div>
              <div className='padding-top-30'>
                {
                  isLoggedIn ?
                    <NavLink style={{ boxShadow: '0px 2px 5px lightgrey' }} to="/searchcandidates" className='card-btn fw-bold active'>Find your ideal candidate now</NavLink>
                    :
                    <NavLink style={{ boxShadow: '0px 2px 5px lightgrey' }} to="/" className='card-btn fw-bold active' data-toggle="modal" data-target="#exampleModalLong">Find your ideal candidate now</NavLink>
                }
              </div>
            </div>
            <div className="col-md-6 col-sm-12 my-auto pt-5 pt-lg-0 pt-md-0" >
              <iframe width="100%" height="380" className="lazy align-middle" style={{ borderBottom: '1px solid grey' }} allowFullScreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" src="https://www.youtube.com/embed/WaL7lKLcvYw?origin=https://fludder.io/employer&showinfo=0&video-id=WaL7lKLcvYw&enablejsapi=1&widgetid=1&color=white&modestbranding=1&rel=0" data-title="Fludder" title="Fludder" frameBorder="0"></iframe>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default Home;