import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import ChatComponent from "./ChatComponent";
import { firebase } from './firebase';

function ChatWithCandidate() {

  const companyProfile = localStorage.getItem("companyProfile");
  const companyId = localStorage.getItem("companyId");
  const location = useLocation();
  const { candidate } = location.state;

  return (
    <>
      <ChatComponent candidate={candidate} companyProfile={companyProfile}/>
    </>
  );
}

export default ChatWithCandidate;
