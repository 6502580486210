import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/themify-icons.css';
import './assets/css/et-line.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/plyr.css';
import './assets/css/flag.css';
import './assets/css/slick.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery.nstSlider.min.css';
import './css/main.css';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';

const Footer = () => {
  return (
    <>
      <footer className="footer-bg">
        <div className="footer-widget-wrapper padding-top-30">
          <div className="container">
            <div className="row d-flex justify-content-center">

              <div className="col-xl-4 col-lg-4 order-lg-1">
                <div className="footer-widget footer-shortcut-link">
                  <div className="widget-inner mt-3 text-center">
                    <ul>
                      <li>
                        <span className='mr-3'>Download our app</span>
                        <span className='mr-3'>
                          <a href="https://apps.apple.com/in/app/fludder/id1536339735" rel="noreferrer" target='_blank' className="apple-app" style={{ color: '#360a65' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" id="apple" width="20" height="28.266" viewBox="0 0 23.663 28.266">
                              <path id="apple-2" data-name="apple" d="M23.662,20.639a16.413,16.413,0,0,1-2.089,4.247q-2.191,3.329-4.365,3.329a7.85,7.85,0,0,1-2.378-.543,7.513,7.513,0,0,0-2.565-.543,6.538,6.538,0,0,0-2.412.561,6.136,6.136,0,0,1-2.242.578q-2.582,0-5.113-4.4A17.422,17.422,0,0,1,0,15.322,10.076,10.076,0,0,1,1.92,8.969,5.9,5.9,0,0,1,6.744,6.523a11.3,11.3,0,0,1,3.006.51,10.939,10.939,0,0,0,2.344.51,8.5,8.5,0,0,0,2.429-.578,9.592,9.592,0,0,1,2.939-.578,6.209,6.209,0,0,1,3.618,1.1,8.772,8.772,0,0,1,1.767,1.7,11.01,11.01,0,0,0-1.936,2,6.05,6.05,0,0,0-1.1,3.516A6.469,6.469,0,0,0,20.979,18.5a5.166,5.166,0,0,0,2.684,2.14ZM17.275.714a6.477,6.477,0,0,1-.492,2.31A6.97,6.97,0,0,1,15.2,5.368a4.816,4.816,0,0,1-1.835,1.223A9.489,9.489,0,0,1,11.6,6.88a7.743,7.743,0,0,1,1.325-4.365A6.768,6.768,0,0,1,17.174,0a1.575,1.575,0,0,1,.042.187,1.575,1.575,0,0,0,.042.187q0,.068.009.17t.009.17Z" fill="#39285d" />
                            </svg>
                          </a>
                        </span>
                        <span className='mr-3'>
                          <a href="https://play.google.com/store/apps/details?id=com.fludder.fludderapp" rel="noreferrer" target='_blank' className="android-app" style={{ color: '#360a65' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" id="google-play" width="20" height="28.266" viewBox="0 0 25.507 28.266">
                              <path id="google-play-2" data-name="google-play" d="M0,26.814V2.452A1.956,1.956,0,0,1,1.211.5L15.345,14.633,1.211,28.766A2.036,2.036,0,0,1,0,26.814Zm19.853-7.672L4.375,28.026,16.556,15.845Zm4.779-6.192a2.045,2.045,0,0,1,.875,1.682,2.1,2.1,0,0,1-.808,1.682L21.4,18.2l-3.634-3.567L21.4,11.064ZM4.375,1.241l15.479,8.884-3.3,3.3Z" transform="translate(0 -0.5)" fill="#39285d" />
                            </svg>
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-lg-4 order-lg-1">
                <div className="footer-widget footer-shortcut-link">
                  <div className="widget-inner mt-3 text-center">
                    <ul>
                      <li>
                        <a href="https://acedmyinterview.com/privacy-policy/" rel="noreferrer" target="_blank" style={{ color: '#360a65' }}>Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-4 col-lg-4 order-lg-2  ">
                <div className="footer-logo text-center">
                  <NavLink to="/">
                    <img
                      src={require("./images/footer-logo.png")}
                      className="img-fluid"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 order-lg-3">
                <div className="footer-social text-center">
                  <ul className="social-icons">
                    <li>
                      <NavLink to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" id="facebook" width="20" height="23.67" viewBox="0 0 11.504 23.67">
                          <path id="facebook-2" data-name="facebook" d="M2.937,23.67V12.563H0v-4H2.937V5.149C2.937,2.465,4.672,0,8.669,0a24.271,24.271,0,0,1,2.815.155L11.39,3.89s-1.22-.012-2.552-.012c-1.441,0-1.673.664-1.673,1.767v2.92H11.5l-.189,4H7.165V23.67H2.937" fill="#39285d" />
                        </svg>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" id="twitter" width="20" height="22.509" viewBox="0 0 27.011 22.509">
                          <path id="twitter-2" data-name="twitter" d="M27.011,5.633A7.259,7.259,0,0,1,26,7.078a7.445,7.445,0,0,1-1.822,1.536q.03.241.045.452a16.389,16.389,0,0,1-1.159,6.278,16.532,16.532,0,0,1-6.579,8.175A17.3,17.3,0,0,1,6.188,25.4,11.825,11.825,0,0,1,0,23.008a11.531,11.531,0,0,0,8.251-2.379,5.684,5.684,0,0,1-5.239-3.884,3.523,3.523,0,0,0,1.174.06,10.926,10.926,0,0,0,1.325-.181A6.211,6.211,0,0,1,1.747,13.7a6.3,6.3,0,0,1-.633-2.77,5.29,5.29,0,0,0,2.5.753A6.457,6.457,0,0,1,1.159,7.7a7.388,7.388,0,0,1,.678-3.6A18.925,18.925,0,0,0,8.266,8.914,13.318,13.318,0,0,0,13.31,9.969a6.74,6.74,0,0,1,.678-4.276,5.825,5.825,0,0,1,3.478-2.529,5.133,5.133,0,0,1,5.149,1.6A9.166,9.166,0,0,0,26.2,3.434a5.437,5.437,0,0,1-2.469,3.192,15.657,15.657,0,0,0,3.282-.993Z" transform="translate(0 -2.999)" fill="#39285d" />
                        </svg>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" id="bxl-instagram-alt" width="20" height="21.348" viewBox="0 0 21.312 21.348">
                          <path id="Path_60" data-name="Path 60" d="M25.739,10.772a7.728,7.728,0,0,0-.5-2.623,5.456,5.456,0,0,0-3.117-3.117,7.818,7.818,0,0,0-2.587-.5c-1.139-.051-1.5-.065-4.39-.065s-3.261,0-4.391.065a7.818,7.818,0,0,0-2.586.5A5.453,5.453,0,0,0,5.057,8.149a7.757,7.757,0,0,0-.5,2.586c-.051,1.139-.066,1.5-.066,4.391s0,3.259.066,4.391a7.8,7.8,0,0,0,.5,2.588,5.456,5.456,0,0,0,3.117,3.115,7.792,7.792,0,0,0,2.586.533c1.139.051,1.5.066,4.391.066s3.261,0,4.391-.066a7.8,7.8,0,0,0,2.587-.5,5.462,5.462,0,0,0,3.117-3.117,7.773,7.773,0,0,0,.5-2.588c.051-1.139.066-1.5.066-4.391s0-3.257-.069-4.39Zm-10.6,9.82a5.471,5.471,0,1,1,5.471-5.47,5.469,5.469,0,0,1-5.471,5.47Zm5.689-9.869a1.275,1.275,0,1,1,1.274-1.276A1.274,1.274,0,0,1,20.833,10.722Z" transform="translate(-4.495 -4.47)" fill="#39285d" />
                          <path id="Path_61" data-name="Path 61" d="M20.594,17.017a3.554,3.554,0,1,1-3.554-3.554,3.554,3.554,0,0,1,3.554,3.554Z" transform="translate(-6.392 -6.368)" fill="#39285d" />
                        </svg>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" id="bxl-linkedin" width="20" height="21.509" viewBox="0 0 21.546 21.509">
                          <path id="Path_62" data-name="Path 62" d="M9.371,6.821A2.589,2.589,0,1,1,6.781,4.232,2.589,2.589,0,0,1,9.371,6.821Z" transform="translate(-4.192 -4.232)" fill="#39285d" />
                          <path id="Path_63" data-name="Path 63" d="M11.911,13.188V27.555h4.461v-7.1c0-1.875.353-3.69,2.677-3.69,2.292,0,2.321,2.143,2.321,3.808v6.987h4.463V19.676c0-3.87-.833-6.844-5.356-6.844a4.69,4.69,0,0,0-4.223,2.32h-.06V13.187H11.911Zm-7.269,0H9.11V27.555H4.643Z" transform="translate(-4.287 -6.047)" fill="#39285d" />
                        </svg>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-area mt-md-0">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <div className="order-lg-1">
                    <NavLink to="privacypolicy" rel="noreferrer" target="_blank" style={{ color: '#212121' }}><u>Privacy Policy</u></NavLink>
                    <NavLink to="termsandconditions" rel="noreferrer" target="_blank" style={{ color: '#212121' }}>&emsp;&emsp;<u>Terms & Conditions</u></NavLink>
                    <NavLink to="DataDeletionInstructions" rel="noreferrer" target="_blank" style={{ color: '#212121' }}>&emsp;&emsp;<u>Unsubscribe</u></NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="footer-bottom">
                  <div className="text-center">
                    <div className="order-lg-1">
                      <p className="copyright-text" style={{ color: '#212121' }}>
                        Copyright <NavLink to="/">Fludder</NavLink> 2023, All rights reserved
                      </p>
                    </div>
                    {/* <div className="col-xl-4 col-lg-3 order-lg-3">
                      <div className="back-to-top">
                        <NavLink to="/" style={{ color: '#360a65' }}>
                          Back to top
                          <i className="fas fa-angle-up" />
                        </NavLink>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer End */}
    </>
  )
};
export default Footer;