import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/themify-icons.css';
import './assets/css/et-line.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/plyr.css';
import './assets/css/flag.css';
import './assets/css/slick.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery.nstSlider.min.css';
import './css/main.css';
import { NavLink, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import Axios from 'axios';
import CandidateVideos from './components/CandidateVideos';
import Pagination from './components/Pagination';
import FeatherIcon from 'feather-icons-react';
import ReactJsAlert from "reactjs-alert";
import ReactPlayer from 'react-player';
import { Formik } from "formik";
import * as yup from 'yup';
import Multiselect from 'multiselect-react-dropdown';

const CandidateProfile = () => {

  const [alertStatus, setAlertStatus] = useState(false);
  const [type, setType] = useState("success");
  const [title, setTitle] = useState("");
  const [favorite, setFavorite] = useState("");
  const [options, setOptions] = useState([]);
  const [interview, setInterview] = useState(false)
  const [offer, setOffer] = useState(false)

  const multiSelectStyle = {
    searchBox: {
      border: "none",
      padding: '1px'
    },
    inputField: { // To change input field position or margin
      marginTop: '0px',
    },
  };

  const initialValues = {
    categoryAndJobTitleId: ''
  }

  useEffect(() => {
    const jsonobj = {
      companyId: Number(localStorage.getItem('companyId')),
      user_information_id: Number(candidate.user_information_id)
    }
    Axios.post("https://fludder.io/admin/WebApi/checkFavoriteAndAddToViewedUsers",
      JSON.stringify(jsonobj)
    )
      .then((res) => {
        if (res.data.type === "success")
          setFavorite('red')
        if (res.data.interview === "Y")
          setInterview(true)
        if (res.data.offer === "Y")
          setOffer(true)
      })
    Axios.post("https://fludder.io/admin/WebApi/getCompanyJobDescriptions",
      {
        companyId: localStorage.getItem('companyId')
      }
    )
      .then((res) => {
        setOptions(res.data);
      })
    const script = document.createElement('script');
    script.src = "https://fludder.io/assets/webportaljs/content/js/feather.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }

  }, []);

  const location = useLocation()
  const { candidate } = location.state

  // console.log('Candidate Information : ', candidate);

  const [candidateVideos, setCandidateVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [candidateVideosPerPage] = useState(3);

  const onClick = (requestType) => {
    console.log("requestType : ", requestType);
    let jsonData = { user_information_id: candidate.user_information_id, companyId: localStorage.getItem('companyId'), requestType: requestType }
    Axios.request({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}WebApi/request`,
      data: JSON.stringify(jsonData),
    }).then((res) => {
      setAlertStatus(true);
      setType(res.data.type);
      setTitle(res.data.message);
      if (requestType === 'offer')
        setOffer(true)
      if (requestType === '')
        setInterview(true)
    });
  }

  // const makeOffer = () => {
  //   Axios.request({
  //     method: "post",
  //     url: `${process.env.REACT_APP_BASE_URL}WebApi/makeOffer`,
  //     data: { user_information_id: candidate.user_information_id, companyId: localStorage.getItem('companyId') },
  //   }).then((res) => {
  //     setAlertStatus(true);
  //     setType(res.data.type);
  //     setTitle(res.data.message);
  //   });
  // }


  useEffect(() => {
    setLoading(true);
    const fetchCandidateVideos = async () => {
      Axios.request({
        method: "post",
        url: "https://fludder.io/admin/WebApi/getCandidateVideos",
        data: { user_information_id: candidate.user_information_id },
      }).then((res) => {
        setCandidateVideos(res.data);
        setLoading(false);
      });
    }
    fetchCandidateVideos();
  }, [candidate.user_information_id]);

  const indexOfLastCandidateVideo = currentPage * candidateVideosPerPage;
  const indexOfFirstCandidateVideo = indexOfLastCandidateVideo - candidateVideosPerPage;
  const currentCandidateVideos = candidateVideos.slice(indexOfFirstCandidateVideo, indexOfLastCandidateVideo);

  //Change page
  const paginate = pageNumber => { setCurrentPage(pageNumber) };

  let rating = 0;
  if (candidate.userTotalAnswers > 0) {
    const totalRatingOutOff = candidate.userTotalAnswers * 60;
    rating = (candidate.userTotalRatings / totalRatingOutOff) * 10;
  }

  const getCandidateName = (candidateName) => {
    let firstname = candidateName.substr(0, candidateName.indexOf(' '));
    let lastname = (candidateName.substr(candidateName.indexOf(' ') + 1).trim()).charAt(0);
    return firstname + ' ' + lastname;
  }

  const addToFavorite = (user_information_id) => {

    const jsonobj = {
      companyId: localStorage.getItem('companyId'),
      userInformationId: user_information_id
    }
    // console.log("Add to favorite user ", JSON.stringify(jsonobj))
    try {
      Axios.post("https://fludder.io/admin/WebApi/addToFavorite",
        JSON.stringify(jsonobj)
      )
        .then((res) => {
          setAlertStatus(true);
          setType(res.data.type);
          setTitle(res.data.message);
          setFavorite('red')
        })
    }
    catch (error) {
      setAlertStatus(true);
      setType('error');
      setTitle('Someting went worng!');
      console.log('error : ' + error);
      return error;
    }
  }

  const removeFromFavorite = (user_information_id) => {
    const jsonobj = {
      companyId: localStorage.getItem('companyId'),
      user_information_id: user_information_id
    }
    try {
      Axios.post("https://fludder.io/admin/WebApi/removeFromFavorite",
        JSON.stringify(jsonobj)
      )
        .then((res) => {
          setAlertStatus(true);
          setType(res.data.type);
          setTitle(res.data.message);
          setFavorite('')
        })
    }
    catch (error) {
      setAlertStatus(true);
      setType('error');
      setTitle('Someting went worng!');
      console.log('error : ' + error);
      return error;
    }
  }
  const companyProfile = localStorage.getItem('companyProfile');
  const ReviewSchema = yup.object({
    categoryAndJobTitleId: yup.number().required('Please select job title'),
  })
  return (
    <>
      {/* <Header companyProfile={companyProfile} /> */}
      <div className="alice-bg d-flex justify-content-center padding-top-30 padding-bottom-40">
        <div className="container px-0" >
          <div className="row">
            <div className="col">
              <div className="candidate-details px-4">
                <div className="title-and-info">
                  <div className="title">
                    <div className="title-body">
                      <h4>{getCandidateName(candidate.user_name)}&nbsp;&nbsp;
                        {favorite === '' ?
                          <button
                            title='Add to favorite candidates'
                            onClick={() => addToFavorite(candidate.user_information_id)}
                          // data-toggle="modal"
                          // data-target="#exampleModalLong"
                          >
                            <FeatherIcon icon="heart" fill={favorite} />
                          </button>
                          :
                          <button title='Remove from favorite candidates' onClick={() => removeFromFavorite(candidate.user_information_id)}><FeatherIcon icon="heart" fill={favorite} /></button>
                        }
                      </h4>
                      {
                        candidate.user_city ?
                          <div className="info">
                            <span className="candidate-location">
                              <Icon.MapPin />
                              {candidate.user_city}
                            </span>
                          </div>
                          :
                          null
                      }
                      {
                        candidate.user_description ?
                          <div className="info">
                            <span className="candidate-designation">
                              <Icon.CheckSquare />
                              {candidate.user_description}
                            </span>
                          </div>
                          :
                          null
                      }
                    </div>
                  </div>
                  <div className="download-resume">
                    {
                      interview ?
                        <button type='button' disabled style={{ opacity: 0.5 }}>Interview Request Sent</button>
                        :
                        <button type='button' onClick={() => onClick("")}>Request An Interview</button>
                    }
                    {/* <NavLink to="/candidateprofile" onClick={onClick}>Request An Interview</NavLink> */}
                  </div>
                </div>

                <div className="details-information section-padding-30">
                  <div className="row">
                    <div className="col-xl-8 col-lg-8">

                      <div className="portfolio details-section">
                        <h4>
                          <Icon.PlayCircle />
                          Candidate Responses
                        </h4>
                        <CandidateVideos candidateVideos={currentCandidateVideos} loading={loading} />
                        {
                          candidateVideos.length > 3 ?
                            <Pagination
                              candidateVideosPerPage={candidateVideosPerPage}
                              totalCandidateVideos={candidateVideos.length}
                              paginate={paginate}
                              currentPage={currentPage}
                            />
                            :
                            null
                        }


                      </div>

                    </div>
                    <div className="col-xl-4 col-lg-4">
                      <div className="information-and-contact">
                        <div className="information">
                          <h4>
                            <Icon.Info />
                            &nbsp;Candidate Overview
                          </h4>
                          <ul>
                            <li>
                              <i className="fas fa-star"></i>
                              <span> Fludder Score:</span>
                              <img
                                src={require("./images/ratings/7.png")}
                                alt={rating / 2 + " / 5 stars"}
                                style={{ width: '50%' }}
                              />
                            </li>
                            <li><i className="fas fa-graduation-cap"></i><span> Level Of Education:</span> {candidate.userLevelOfEducation}</li>
                            <li><i className="fas fa-flag"></i><span> Desired Work Frequency:</span> {candidate.userPositionLookingFor}</li>
                            <li><i className="fas fa-map-marker-alt"></i><span> Desired Work Location:</span> {candidate.userLocationPreference}</li>
                            <li>
                              <i className="fas fa-calendar-check"></i>
                              <span> Weekend Availability: {1 == 2 ? 'Yes' : 'No'}</span>
                            </li>
                            {/* <li><i className="fas fa-hourglass-half"></i><span> Desired Shift Length:</span> {candidate.userWorkingHours}</li> */}
                            {/* <li><i className="fas fa-briefcase"></i><span> Desired Position Type:</span> {candidate.userTypeOfWorkLookingFor}</li> */}
                            <li>
                              <i className="fas fa-id-card"></i>
                              <span> Driver’s License: {candidate.userDrivingLicenseFlag === 2 ? 'Yes' : 'No'}</span>

                            </li>
                          </ul>
                        </div>
                        <div className="buttons">
                          {/* <NavLink state={{ candidate: candidate }} to="/chatwithcandidate" className="button contact-button" >Message</NavLink> */}
                          <button className="button contact-button" style={{ opacity: 0.5 }} disabled title='Coming Soon...'>Message</button>
                          {
                            interview ?
                              <button className="button contact-button" disabled style={{ opacity: 0.5 }} >Interview Request Sent</button>
                              :
                              <button className="button contact-button" onClick={() => onClick("")} >Request An Interview</button>
                          }
                          {
                            offer ?
                              <button disabled className="button contact-button" style={{ opacity: 0.5 }} >Offer Request Sent</button>
                              :
                              <button onClick={() => onClick('offer')} className="button contact-button" >Make Offer</button>
                          }


                        </div>
                        <div className="modal fade contact-form-modal" id="exampleModal" tabIndex={-1} role="dialog" aria-hidden="true">
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-body">
                                <h4>
                                  <Icon.Edit />
                                  Contact Me
                                </h4>
                                <form action="#">
                                  <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Your Name" />
                                  </div>
                                  <div className="form-group">
                                    <input type="email" className="form-control" placeholder="Your Email" />
                                  </div>
                                  <div className="form-group">
                                    <textarea className="form-control" placeholder="Your Message" defaultValue={""} />
                                  </div>
                                  <button className="button">Submit</button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Candidates Details End */}
      {/* Modal */}
      <div className="account-entry">
        {candidateVideos && candidateVideos.length > 0 ?
          candidateVideos.map((candidateVideo, i) => {
            return (
              <div
                className="modal fade"
                id={"playVideo" + candidateVideo.user_answer_and_review_id}
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
                key={candidateVideo.user_answer_and_review_id}
              >
                <div className="modal-dialog" role="document" style={{ maxWidth: '800px' }}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <ReactPlayer
                        controls={true}
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        onContextMenu={e => e.preventDefault()}
                        width="100%"
                        height="420px"
                        // url={'https://fludder.io/admin/uploads/answer_videos/' + candidateVideo.user_answer_video}
                        url={'https://videocompressionoutputbucket.s3.us-east-2.amazonaws.com/assets/ddd/MP4/' + candidateVideo.user_answer_video.split(".")[0] + '.mp4'}
                      />
                      <div className="shortcut-login">
                        <h5 className="modal-title" style={{ color: '#f9a132' }}>
                          <Icon.CheckSquare />
                          &nbsp;{candidateVideo.domain_name}
                        </h5>
                        <p style={{ color: '#360a65' }}>
                          <Icon.Info />
                          &nbsp;Question : {candidateVideo.question}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })

          :
          null
        }
      </div>
      <div className="account-entry">
        <div
          className="modal fade"
          id="exampleModalLong"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <i data-feather="user" />
                  Select job description
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {/* <form action="#"> */}
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, { resetForm }) => {
                    // document.getElementById("closeModalButton").click();
                    const jsonobj = {
                      companyId: localStorage.getItem('companyId'),
                      userInformationId: candidate.user_information_id,
                      categoryAndJobTitleId: values.categoryAndJobTitleId
                    }
                    try {
                      Axios.post("https://fludder.io/admin/WebApi/addToFavorite",
                        JSON.stringify(jsonobj)
                      )
                        .then((res) => {
                          setAlertStatus(true);
                          setType(res.data.type);
                          setTitle(res.data.message);
                          setFavorite('red')
                        })
                    }
                    catch (error) {
                      setAlertStatus(true);
                      setType('error');
                      setTitle('Someting went worng!');
                      console.log('error : ' + error);
                      return error;
                    }
                    resetForm({ values: initialValues })
                  }}
                  validationSchema={ReviewSchema}
                >
                  {(props) => (
                    <div>
                      <div className="form-group">
                        <Multiselect
                          options={options.map((singleOption, i) => { return ({ name: singleOption.jobTitle, id: singleOption.categoryAndJobTitleId }) })} // Options to display in the dropdown
                          onSelect={
                            (selectedList, selectedItem) => {
                              props.setFieldValue('categoryAndJobTitleId', selectedItem.id)
                            }
                          } // Function will trigger on select event
                          displayValue="name" // Property name to display in the dropdown options
                          placeholder="Select Job Title"
                          style={multiSelectStyle}
                          singleSelect={true}
                          onChange={props.handleChange('categoryAndJobTitleId')}
                        />
                        <p style={{ color: 'red' }}>{props.touched.categoryAndJobTitleId && props.errors.categoryAndJobTitleId}</p>
                      </div>
                      <button
                        type="submit"
                        className="button primary-bg btn-block"
                        onClick={props.handleSubmit}
                      >
                        Add To Favorite
                      </button>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactJsAlert
        status={alertStatus} // true or false
        type={type} // success, warning, error, info
        title={title}
        autoCloseIn='30000'
        Close={() => setAlertStatus(false)}
      />
    </>
  )
}
export default CandidateProfile;