import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/themify-icons.css';
import './assets/css/et-line.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/plyr.css';
import './assets/css/flag.css';
import './assets/css/slick.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery.nstSlider.min.css';
import './css/main.css';
import './assets/css/dashboard.css';
import Header from './Header';
import FeatherIcon from 'feather-icons-react';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import EmployerLetSideBar from './EmployerLeftSideBar';
import { useLocation } from 'react-router-dom';


const EmployerProfile = (companyProfile1) => {

    const [isLoading, setIsLoading] = useState(true);
    const [companyProfile, setCompanyProfile] = useState(companyProfile1);
    useEffect(() => {
        const jsonObject = { companyId: localStorage.getItem('companyId') };
        Axios.post("https://fludder.io/admin/WebApi/getEmployerProfileById",
            JSON.stringify(jsonObject)
        )
            .then((res) => {
                setCompanyProfile(res.data.companyProfile);
                setIsLoading(false);
            })
    }, []);

    return (
        <>
            {/* Breadcrumb */}
            <div className="alice-bg padding-bottom-40" style={{ paddingTop: '40px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="breadcrumb-area">
                                <h1>Employer Dashboard</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Employer Dashboard</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumb End */}
            {/* Job Listing */}
            {
                isLoading ?
                    <p>Loadin...</p>
                    :
                    <div className="alice-bg section-padding-bottom">
                        <div className="container no-gliters">
                            <div className="row no-gliters">
                                <div className="col">
                                    <div className="dashboard-container">
                                        <div className="dashboard-content-wrapper">
                                            <div className="dashboard-section user-statistic-block">
                                                <div className="user-statistic">
                                                    <FeatherIcon icon="command" />
                                                    <h3>{companyProfile.viewedUsers !== null && companyProfile.viewedUsers !== '' ? companyProfile.viewedUsers.split(',').length : 0}</h3>
                                                    <span>Total Candidate Viewed</span>
                                                </div>
                                                <div className="user-statistic">
                                                    <FeatherIcon icon="users" />
                                                    <h3>{companyProfile.favoriteUsers !== null && companyProfile.favoriteUsers !== '' ? companyProfile.favoriteUsers : 0}</h3>
                                                    <span>Total Favourite Candidates</span>
                                                </div>
                                                <div className="user-statistic">
                                                    <FeatherIcon icon="file-text" />
                                                    <h3>{companyProfile.currentRemainingViews !== null && companyProfile.currentRemainingViews !== '' ? companyProfile.currentRemainingViews : 0}</h3>
                                                    <span>Remaining Candidate Views</span>
                                                </div>
                                            </div>
                                            <form action="#" className="dashboard-form">
                                                <div className="dashboard-section upload-profile-photo" style={{ marginTop: 10 }}>
                                                    <div className="update-photo">
                                                        {
                                                            companyProfile.companyLogo !== null && companyProfile.companyLogo !== '' ?
                                                                <img className="image" src={`${process.env.REACT_APP_BASE_URL}uploads/companyLogos/` + companyProfile.companyLogo} alt="Company Logo" />
                                                                :
                                                                <img className="image" src="dashboard/images/defaultCompanyLogo.png" alt="Company Logo" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="dashboard-section basic-info-input">
                                                    <h4><i data-feather="user-check"></i>Basic Info</h4>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Company Name</label>
                                                        <div className="col-sm-9">
                                                            <p>{companyProfile.companyName}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Email Address</label>
                                                        <div className="col-sm-9">
                                                            <p>{companyProfile.companyEmail}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Phone</label>
                                                        <div className="col-sm-9">
                                                            <p>{companyProfile.companyPhoneNumber}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Company Address</label>
                                                        <div className="col-sm-9">
                                                            <p>{companyProfile.companyAddress}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">About Us</label>
                                                        <div className="col-sm-9">
                                                            <p>{companyProfile.companyAboutUs}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <EmployerLetSideBar employerName={companyProfile.companyName} activeMenu="dashboard" companyProfile={companyProfile} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

            {/* Job Listing End */}
            {/* Optional JavaScript */}
            {/* jQuery first, then Popper.js, then Bootstrap JS */}
        </>
    )
};

export default EmployerProfile;