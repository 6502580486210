import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/themify-icons.css';
import './assets/css/et-line.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/plyr.css';
import './assets/css/flag.css';
import './assets/css/slick.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery.nstSlider.min.css';
import './css/main.css';
import './assets/css/dashboard.css';
import Header from './Header';
import EmployerLeftSideBar from './EmployerLeftSideBar';
import FeatherIcon from 'feather-icons-react';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from './components/Pagination';


const ManageJobs = () => {
    let companyProfile = useLocation().state;
    const [companyJobDescriptions, setCompanyJobDescriptions] = useState([]);
    const [RecordsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const jsonObject = {
            companyId: localStorage.getItem('companyId')
        }
        Axios.post("https://fludder.io/admin/WebApi/getCompanyJobDescriptions",
            JSON.stringify(jsonObject)
        )
            .then((res) => {
                setCompanyJobDescriptions(res.data);
            })
    }, []);
    const indexOfLast = currentPage * RecordsPerPage;
    const indexOfFirst = indexOfLast - RecordsPerPage;
    const currentRecords = companyJobDescriptions.slice(indexOfFirst, indexOfLast);
    //Change page
    const paginate = pageNumber => { setCurrentPage(pageNumber) };

    return (
        <>
            {/* Breadcrumb */}
            <div className="alice-bg padding-bottom-40" style={{ paddingTop: '40px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="breadcrumb-area">
                                <h1>Manage Jobs</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item active" aria-current="page">Manage Jobs</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumb End */}
            {/* Job Listing */}
            <div className="alice-bg section-padding-bottom">
                <div className="container no-gliters">
                    <div className="row no-gliters">
                        <div className="col">
                            <div className="dashboard-container">
                                <div className="dashboard-content-wrapper">
                                    <div className="manage-job-container">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Job Title</th>
                                                    <th>Job Description</th>
                                                    <th>Favorite Candidates</th>
                                                    <th>Message Sent</th>
                                                    <th>Interview Requested</th>
                                                    <th>Offer Sent</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    currentRecords.map((singleCompanyJobDescriptions, i) => {
                                                        return (
                                                            <tr className="job-items" key={i}>
                                                                <td className="title">{singleCompanyJobDescriptions.jobTitle}</td>
                                                                <td className="title">{singleCompanyJobDescriptions.companyJobDescription}</td>
                                                                <td className="application">{singleCompanyJobDescriptions.favoriteUserCount} Candidates</td>
                                                                <td className="application">{companyProfile.messageSentUsers !== null && companyProfile.messageSentUsers !== '' ? companyProfile.messageSentUsers.split(',').length : '0'} Candidates</td>
                                                                <td className="application">{companyProfile.interviewRequestSentUsers !== null && companyProfile.interviewRequestSentUsers !== '' ? companyProfile.interviewRequestSentUsers.split(',').length : '0'} Candidates</td>
                                                                <td className="status">{companyProfile.offerSentUsers !== null && companyProfile.offerSentUsers !== '' ? companyProfile.offerSentUsers.split(',').length : '0'} Candidates</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            companyJobDescriptions.length > 3 ?
                                                <Pagination
                                                    candidateVideosPerPage={RecordsPerPage}
                                                    totalCandidateVideos={companyJobDescriptions.length}
                                                    paginate={paginate}
                                                    currentPage={currentPage}
                                                />
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                <EmployerLeftSideBar employerName="DSHG Sonic LLC" activeMenu="manageJobs" companyProfile={companyProfile} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Job Listing End */}
            {/* Optional JavaScript */}
            {/* jQuery first, then Popper.js, then Bootstrap JS */}
        </>
    )
};

export default ManageJobs;