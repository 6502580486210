import React from 'react';
import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/themify-icons.css';
import './assets/css/et-line.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/plyr.css';
import './assets/css/flag.css';
import './assets/css/slick.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery.nstSlider.min.css';
import './css/main.css';
// import Header from './Header';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as Icon from 'react-feather';
import HoverVideoPlayer from 'react-hover-video-player';
import Axios from 'axios';
import Multiselect from 'multiselect-react-dropdown';
import Pagination from './components/Pagination';
import { useLocation } from 'react-router-dom';
import ReactJsAlert from "reactjs-alert"

const SearchCandidates = () => {

  const location = useLocation();
  const { state } = location;
  const [candidates, setCandidates] = useState([]);
  const [options, setOptions] = useState([]);
  const [zipCode, setZipCode] = useState();
  const [userLevelOfEducationFilter, setUserLevelOfEducationFilter] = useState('');
  const [fullTimeCheckBox, setFullTimeCheckBox] = useState(false);
  const [partTimeCheckBox, setPartTimeCheckBox] = useState(false);
  const [asNeedeCheckBox, setAsNeededCheckBox] = useState(false);
  const [allLocationCheckBox, setAllLocationCheckBox] = useState(false);
  const [oneLocationCheckBox, setOneLocationCheckBox] = useState(false);
  const [multipleLocationCheckBox, setMultipleLocationCheckBox] = useState(false);
  const [partialRemoteLocationCheckBox, setPartialRemoteLocationCheckBox] = useState(false);
  const [onTheRoadLocationCheckBox, setOnTheRoadLocationCheckBox] = useState(false);
  const [remoteLocationCheckBox, setRemoteLocationCheckBox] = useState(false);
  const [weekEndAvailability, setWeekEndAvailability] = useState();
  const [eightHourCheckBox, setEightHourCheckBox] = useState(false);
  const [tenHourCheckBox, setTenHourCheckBox] = useState(false);
  const [twelHourCheckBox, setTwelHourCheckBox] = useState(false);
  const [contractEmployeeCheckBox, setContractEmployeeCheckBox] = useState(false);
  const [temporaryHireCheckBox, setTemporaryHireCheckBox] = useState(false);
  const [internCheckBox, setInternCheckBox] = useState(false);
  const [drivingLicense, setDrivingLicense] = useState();
  const [resumeRequired, setResumeRequired] = useState();
  const [experienceRequired, setExperienceRequired] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedList, setSelectedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [currentRemainingViews, setCurrentRemainingViews] = useState(0);
  const [alertStatus, setAlertStatus] = useState(false);

  // console.log('user level of education filter: ', userLevelOfEducationFilter);

  //Change page
  const paginate = pageNumber => { setCurrentPage(pageNumber) };

  const [candidateVideosPerPage] = useState(6);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedList(selectedList);
  }

  const onRemove = (selectedList, removedItem) => {
    setSelectedList(selectedList);
  }

  useEffect(() => {
    (async () => {
      if (state !== null && state !== 'undefined' && state !== '') {
        setZipCode(state)
        console.log(state);
      }
      console.log("localStorage : ", localStorage.companyId);
      const newJson = { companyId: localStorage.companyId }
      await Axios.post(`${process.env.REACT_APP_BASE_URL}WebApi/getCategoriesAndJobTitles`, newJson)
        .then((res) => {

          setOptions(res.data);
          console.log('getCategoriesAndJobTitles res: ', res);

        }).catch((err) => {
          console.log('Get categories and job titles error: ', err);
        })

      console.log('After running get CAtegories and Job');

      setLoading(true);

      await Axios.post(`${process.env.REACT_APP_BASE_URL}WebApi/getCandidates`)
        .then((res) => {
          setLoading(false);
          setCandidates(res.data);
          console.log('getCAndidates res : ', res);

        }).catch((err) => {
          console.log('Get candidates Error: ', err);
        })

      await Axios.post(`${process.env.REACT_APP_BASE_URL}WebApi/getCurrentRemainingViews`,
        { companyId: localStorage.companyId }
      )
        .then((res) => {
          setCurrentRemainingViews(res.data);
        }).catch((err) => {
          console.log('Get candidates Error: ', err);
        })

      const script = document.createElement('script');
      script.src = "https://fludder.io/assets/webportaljs/content/js/custom.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }

    })();

  }, []);

  useEffect(() => {
    setLoading(true);
    const jsonObject = {
      userZipCode: zipCode,
      userLevelOfEducation: userLevelOfEducationFilter,
      fullTimeCheckBox: fullTimeCheckBox,
      partTimeCheckBox: partTimeCheckBox,
      asNeedeCheckBox: asNeedeCheckBox,
      allLocationCheckBox: allLocationCheckBox,
      oneLocationCheckBox: oneLocationCheckBox,
      multipleLocationCheckBox: multipleLocationCheckBox,
      partialRemoteLocationCheckBox: partialRemoteLocationCheckBox,
      onTheRoadLocationCheckBox: onTheRoadLocationCheckBox,
      remoteLocationCheckBox: remoteLocationCheckBox,
      weekEndAvailability: weekEndAvailability,
      eightHourCheckBox: eightHourCheckBox,
      tenHourCheckBox: tenHourCheckBox,
      twelHourCheckBox: twelHourCheckBox,
      contractEmployeeCheckBox: contractEmployeeCheckBox,
      temporaryHireCheckBox: temporaryHireCheckBox,
      internCheckBox: internCheckBox,
      drivingLicense: drivingLicense,
      resumeRequired: resumeRequired,
      experienceRequired: experienceRequired,
      selectedList: selectedList
    }
    Axios.post("https://fludder.io/admin/WebApi/searchCandidates",
      jsonObject
    ).then((res) => {
      setCandidates(res.data);
      console.log('The searchCandidates res : ', res);
      setLoading(false);
    }).catch((error) => {
      console.log('Error in search candidates api call : ', error);
      // window.location.reload();
    });
  }, [zipCode, userLevelOfEducationFilter, fullTimeCheckBox, partTimeCheckBox, asNeedeCheckBox, allLocationCheckBox,
    oneLocationCheckBox, multipleLocationCheckBox, partialRemoteLocationCheckBox, onTheRoadLocationCheckBox,
    remoteLocationCheckBox, weekEndAvailability, eightHourCheckBox, tenHourCheckBox, twelHourCheckBox,
    contractEmployeeCheckBox, temporaryHireCheckBox, internCheckBox, drivingLicense, resumeRequired,
    experienceRequired, selectedList]);

  const multiSelectStyle = {
    searchBox: {
      border: "none",
      padding: '1px'
    },
    inputField: { // To change input field position or margin
      marginTop: '0px',
    },
  };

  const indexOfLastCandidateVideo = currentPage * candidateVideosPerPage;
  const indexOfFirstCandidateVideo = indexOfLastCandidateVideo - candidateVideosPerPage;
  const currentCandidateVideos = candidates.slice(indexOfFirstCandidateVideo, indexOfLastCandidateVideo);


  const getCandidateName = (candidateName) => {
    let firstname = candidateName.substr(0, candidateName.indexOf(' '));
    let lastname = (candidateName.substr(candidateName.indexOf(' ') + 1).trim()).charAt(0);
    return firstname + ' ' + lastname;
  }

  return (
    <div>
      <div className="alice-bg padding-bottom-40" style={{ paddingTop: '40px' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumb-area">
                <h1>Candidate Search</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Candidate Search</li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-md-4">
              <div className="breadcrumb-form">
                {/* <form action="#"> */}
                <div className='breadcrumb-formform'>
                  {/* <input type="text" placeholder="Job Description you are interested in" /> */}
                  <Multiselect
                    options={options.map((singleOption, i) => { return ({ name: singleOption.jobTitle, id: i }) })} // Options to display in the dropdown
                    // selectedValues={selectedValue} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    placeholder="Job Description you are interested in"
                    style={multiSelectStyle}
                  />
                </div>
                {/* </form> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="breadcrumb-form">
                {/* <form action="#"> */}
                <div className='breadcrumb-formform'>
                  <input
                    type="number"
                    name='zipCode'
                    onKeyUp={(e) => setZipCode(e.target.value)}
                    placeholder="Enter ZIP Code"
                    defaultValue={zipCode}
                  />
                  <button><Icon.Search /></button>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb End */}
      {/* Job Listing */}
      <div className="alice-bg section-padding-bottom">
        <div className="container">
          <div className="row no-gutters">
            <div className="col">
              <div className="candidate-container">
                <div className="candidate-filter-wrapper">
                  {/* <div className="selected-options same-pad">
                    <div className="selection-title">
                      <h4>You Selected</h4>
                      <a href="/searchcandidates">Clear All</a>
                    </div>
                    <ul className="filtered-options">
                    </ul>
                  </div> */}
                  <div data-id="minimum_education" className="candidate-filter same-pad candidate-type">
                    <h4 className="option-title compress">Minimum Education</h4>
                    <ul style={{ display: 'none' }}>
                      <li><input type="checkbox" onChange={(e) => setUserLevelOfEducationFilter(e.target.checked)} /> &nbsp;No formal education</li>
                      <li><input type="checkbox" onChange={(e) => setUserLevelOfEducationFilter(e.target.checked)} /> &nbsp;Secondary education <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;or high school</li>
                      <li><input type="checkbox" onChange={(e) => setUserLevelOfEducationFilter(e.target.checked)} /> &nbsp;GED</li>
                      <li><input type="checkbox" onChange={(e) => setUserLevelOfEducationFilter(e.target.checked)} /> &nbsp;Vocational qualification</li>
                      <li><input type="checkbox" onChange={(e) => setUserLevelOfEducationFilter(e.target.checked)} /> &nbsp;Bachelor's degree</li>
                      <li><input type="checkbox" onChange={(e) => setUserLevelOfEducationFilter(e.target.checked)} /> &nbsp;Master's degree</li>
                      <li><input type="checkbox" onChange={(e) => setUserLevelOfEducationFilter(e.target.checked)} /> &nbsp;Doctorate or higher</li>
                    </ul>
                  </div>
                  <div data-id="candidate-type" className="candidate-filter same-pad candidate-type">
                    {/* <BrowserView> */}
                    <h4 className="option-title compress">Job Type</h4>
                    {/* </BrowserView>
                    <MobileView>
                      <h4 className="option-title compress">Job Type</h4>
                    </MobileView>
                    <BrowserView> */}
                    <ul style={{ display: 'none' }}>
                      <li><input type="checkbox" onChange={(e) => setFullTimeCheckBox(e.target.checked)} /> &nbsp;Full Time</li>
                      <li><input type="checkbox" onChange={(e) => setPartTimeCheckBox(e.target.checked)} /> &nbsp;Part Time</li>
                      <li><input type="checkbox" onChange={(e) => setAsNeededCheckBox(e.target.checked)} /> &nbsp;As Needed</li>
                    </ul>
                    {/* </BrowserView>
                    <MobileView>
                    <ul style={{ display: 'none' }}>
                      <li><input type="checkbox" onChange={(e) => setFullTimeCheckBox(e.target.checked)} /> &nbsp;Full Time</li>
                      <li><input type="checkbox" onChange={(e) => setPartTimeCheckBox(e.target.checked)} /> &nbsp;Part Time</li>
                      <li><input type="checkbox" onChange={(e) => setAsNeededCheckBox(e.target.checked)} /> &nbsp;As Needed</li>
                    </ul>
                    </MobileView> */}
                  </div>
                  <div data-id="post" className="candidate-filter same-pad candidate-type">
                    <h4 className="option-title compress">Location</h4>
                    <ul style={{ display: 'none' }}>
                      <li><input type="checkbox" onChange={(e) => setAllLocationCheckBox(e.target.checked)} /> &nbsp;All</li>
                      <li><input type="checkbox" onChange={(e) => setOneLocationCheckBox(e.target.checked)} /> &nbsp;One Location</li>
                      <li><input type="checkbox" onChange={(e) => setMultipleLocationCheckBox(e.target.checked)} /> &nbsp;Multiple Locations</li>
                      <li><input type="checkbox" onChange={(e) => setRemoteLocationCheckBox(e.target.checked)} /> &nbsp;Remote</li>
                      <li><input type="checkbox" onChange={(e) => setPartialRemoteLocationCheckBox(e.target.checked)} /> &nbsp;Partial Remote</li>
                      <li><input type="checkbox" onChange={(e) => setOnTheRoadLocationCheckBox(e.target.checked)} /> &nbsp;On-The-Road</li>
                    </ul>
                  </div>
                  <div data-id="experience" className="candidate-filter same-pad candidate-type">
                    <h4 className="option-title compress">Weekend Availability</h4>
                    <ul style={{ display: 'none' }}>
                      <li>
                        <input type="radio" name="my-weekend" onChange={() => setWeekEndAvailability(1)} /><label> &nbsp;Yes</label>
                      </li>
                      <li>
                        <input type="radio" name="my-weekend" onChange={() => setWeekEndAvailability(2)} /><label> &nbsp;No</label>
                      </li>
                    </ul>
                  </div>
                  {/* <div data-id="gender" className="candidate-filter same-pad candidate-type">
                    <h4 className="option-title compress">Average shift length</h4>
                    <ul style={{ display: 'none' }}>
                      <li><input type="checkbox" onChange={(e) => setEightHourCheckBox(e.target.checked)} /> &nbsp;8 hours</li>
                      <li><input type="checkbox" onChange={(e) => setTenHourCheckBox(e.target.checked)} /> &nbsp;10 hours</li>
                      <li><input type="checkbox" onChange={(e) => setTwelHourCheckBox(e.target.checked)} /> &nbsp;12 hours</li>
                    </ul>
                  </div> */}
                  {/* <div data-id="gender" className="candidate-filter same-pad candidate-type">
                    <h4 className="option-title compress">Type of employee</h4>
                    <ul style={{ display: 'none' }}>
                      <li><input type="checkbox" onChange={(e) => setContractEmployeeCheckBox(e.target.checked)} /> &nbsp;Contract Employee</li>
                      <li><input type="checkbox" onChange={(e) => setTemporaryHireCheckBox(e.target.checked)} /> &nbsp;Temporary Hire</li>
                      <li><input type="checkbox" onChange={(e) => setInternCheckBox(e.target.checked)} /> &nbsp;Intern</li>
                    </ul>
                  </div> */}

                  <div data-id="gender" className="candidate-filter same-pad candidate-type">
                    <h4 className="option-title compress">Valid driver’s license</h4>
                    <ul style={{ display: 'none' }}>
                      <li>
                        <input type="radio" name="my-resume" onChange={() => setDrivingLicense(1)} /><label> &nbsp;Required</label>
                      </li>
                      <li>
                        <input type="radio" name="my-resume" onChange={() => setDrivingLicense(2)} /><label> &nbsp;Not Required</label>
                      </li>
                    </ul>
                  </div>
                  <div data-id="gender" className="candidate-filter same-pad candidate-type">
                    <h4 className="option-title compress">Resume Required</h4>
                    <ul style={{ display: 'none' }}>
                      <li>
                        <input type="radio" name="my-resume" onChange={() => setResumeRequired(1)} /><label> &nbsp;Yes</label>
                      </li>
                      <li>
                        <input type="radio" name="my-resume" onChange={() => setResumeRequired(2)} /><label> &nbsp;No</label>
                      </li>
                    </ul>
                  </div>
                  {/* <div data-id="gender" className="candidate-filter same-pad candidate-type">
                    <h4 className="option-title compress">Experience Required</h4>
                    <ul style={{ display: 'none' }}>
                      <li>
                        <input type="radio" name="my-radio" onChange={() => setExperienceRequired(1)} /><label> &nbsp;Yes</label>
                      </li>
                      <li>
                        <input type="radio" name="my-radio" onChange={() => setExperienceRequired(2)} /><label> &nbsp;No</label>
                      </li>
                    </ul>
                  </div> */}

                  {clearFilter ? <div className='p-3'>
                    <button type='button' className='btn btn-lg w-100 primary-bg text-white'>Clear Filter</button>
                  </div> : ''}

                </div>
                <div className="filtered-candidate-wrapper">
                  {currentCandidateVideos.length ? (
                    <div className="candidate-view-controller-wrapper">
                      <div className="candidate-view-controller">
                        <div className="controller list active">
                          <Icon.Menu />
                        </div>
                        <div className="controller grid">
                          <Icon.Grid />
                        </div>
                        {/* <div className="candidate-view-filter">
                        <select className="selectpicker">
                          <option value>Most Recent</option>
                          <option value="california">Top Rated</option>
                        </select>
                      </div> */}
                      </div>
                      {/* <div className="showing-number">
                      <span>Showing 1–10 of 28 Jobs</span>
                    </div> */}
                    </div>) : null}
                  {loading ? (
                    <h4>Loading...</h4>)
                    : (
                      <>
                        <div className="candidate-filter-result">
                          {currentCandidateVideos.length ? (currentCandidateVideos.map((candidate, i) => {
                            return (
                              <div className="candidate" key={i}>
                                <div className="body">
                                  <div className="feature-thumb" style={{ position: 'relative' }}>
                                    <HoverVideoPlayer
                                      videoSrc={'https://videocompressionoutputbucket.s3.us-east-2.amazonaws.com/assets/ddd/MP4/' + candidate.user_answer_video.substr(0, candidate.user_answer_video.lastIndexOf('.')) + '.mp4'}
                                      controlsList="nodownload nofullscreen noprogressbar"
                                      loadingOverlay={
                                        <div className="loading-overlay">
                                          <div className="loading-spinner" />
                                        </div>
                                      }
                                    />
                                  </div>
                                  <div className="content">
                                    <h4>{getCandidateName(candidate.user_name)}</h4>
                                    <div className="info">
                                      {
                                        candidate.user_city ?
                                          <span className="location"><Icon.MapPin />{candidate.user_city}</span>
                                          :
                                          null
                                      }
                                      {
                                        candidate.user_description ?
                                          <span className="work-post"><p><Icon.CheckSquare />{candidate.user_description}<NavLink state={{ candidate: candidate }} to="/candidateprofile" style={{ color: '#360a65' }}>load more</NavLink></p></span>
                                          :
                                          null
                                      }
                                    </div>
                                  </div>
                                  <div>
                                    <div className="button-area" style={{ marginLeft: '2px', marginRight: '2px' }}>
                                      {
                                        currentRemainingViews > 0 ?
                                          <NavLink state={{ candidate: candidate }} to="/candidateprofile">View Profile</NavLink>
                                          :
                                          <NavLink onClick={()=>setAlertStatus(true)}>View Profile</NavLink>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })) : (<h4>No candidates found</h4>)}
                        </div>
                        {
                          candidates.length > 6 ?
                            <Pagination
                              candidateVideosPerPage={candidateVideosPerPage}
                              totalCandidateVideos={candidates.length}
                              paginate={paginate}
                              currentPage={currentPage}
                            />
                            :
                            null
                        }
                      </>
                    )}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactJsAlert
            status={alertStatus} // true or false
            type={"warning"} // success, warning, error, info
            title={"Your free views are over. You need to upgrade."}
            autoCloseIn='30000'
            Close={() => setAlertStatus(false)}
        />
    </div>
  )
}

export default SearchCandidates