import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/themify-icons.css';
import './assets/css/et-line.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/plyr.css';
import './assets/css/flag.css';
import './assets/css/slick.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery.nstSlider.min.css';
import './css/main.css';
import './assets/css/dashboard.css';
import Header from './Header';
import EmployerLeftSideBar from './EmployerLeftSideBar';
import FeatherIcon from 'feather-icons-react';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useLocation } from 'react-router-dom';
import { Formik } from "formik";
import * as yup from 'yup';
import ReactJsAlert from "reactjs-alert";
import { useNavigate } from "react-router-dom";


const PostJobDescription = () => {
    let navigate = useNavigate();
    let companyProfile = useLocation().state;
    const initialValues = {
        companyId: localStorage.getItem('companyId'),
        categoryAndJobTitleId: '',
        companyJobDescription: ''
    }
    const [options, setOptions] = useState([]);
    const [alertStatus, setAlertStatus] = useState(false);
    const [type, setType] = useState("success");
    const [title, setTitle] = useState("");
    const onSelect = (selectedList, selectedItem) => {
        console.log("Selected Item : " + selectedItem.id);
    }

    const onRemove = (selectedList, removedItem) => {

    }
    useEffect(() => {
        Axios.post("https://fludder.io/admin/WebApi/getCategoriesAndJobTitles")
            .then((res) => {
                setOptions(res.data);
            })
    }, []);

    const multiSelectStyle = {
        searchBox: {
            border: "none",
            padding: '1px'
        },
        inputField: { // To change input field position or margin
            marginTop: '0px',
        },
    };

    const ReviewSchema = yup.object({
        categoryAndJobTitleId: yup.number().required('Please select job title'),
        companyJobDescription: yup.string().required('Please enter job description').min(3, 'Job description should contain atleast 3 characters!')
    })

    return (
        <>
            {/* Breadcrumb */}
            <div className="alice-bg padding-bottom-40" style={{ paddingTop: '40px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="breadcrumb-area">
                                <h1>Post Job Description</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Post Job Description</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumb End */}
            {/* Job Listing */}
            <div className="alice-bg section-padding-bottom">
                <div className="container no-gliters">
                    <div className="row no-gliters">
                        <div className="col">
                            <div className="dashboard-container">
                                <div className="dashboard-content-wrapper">
                                    <form action="#" className="dashboard-form">
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={(values, { resetForm }) => {
                                                try {
                                                    const jsonObject = {
                                                        companyId: localStorage.getItem('companyId'),
                                                        categoryAndJobTitleId: values.categoryAndJobTitleId+1,
                                                        companyJobDescription: values.companyJobDescription
                                                    }
                                                    console.log("jsonObject : ",jsonObject);
                                                    Axios.post("https://fludder.io/admin/WebApi/postJobDescription",
                                                        JSON.stringify(jsonObject)
                                                    )
                                                        .then((res) => {
                                                            setAlertStatus(true);
                                                            setType(res.data.type);
                                                            setTitle(res.data.message);
                                                        })
                                                }
                                                catch (error) {
                                                    setAlertStatus(true);
                                                    setType('error');
                                                    setTitle('Someting went worng! Registration process not completed successfully.');
                                                    console.log('error : ' + error);
                                                    return error;
                                                }
                                                resetForm({ values: initialValues })
                                            }}
                                            validationSchema={ReviewSchema}
                                        >
                                            {(props) => (
                                                <div className="basic-info-input">
                                                    <h4><i data-feather="user-check"></i>Job Description</h4>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Job Title</label>
                                                        <div className="col-sm-9">
                                                            {/* <input type="text" className="form-control" placeholder="Job Title" /> */}
                                                            <Multiselect
                                                                options={options.map((singleOption, i) => { return ({ name: singleOption.jobTitle, id: i }) })} // Options to display in the dropdown
                                                                // selectedValues={selectedValue} // Preselected value to persist in dropdown
                                                                onSelect={
                                                                    (selectedList, selectedItem) => {
                                                                        props.setFieldValue('categoryAndJobTitleId', selectedItem.id)
                                                                    }
                                                                } // Function will trigger on select event
                                                                // onRemove={onRemove} // Function will trigger on remove event
                                                                displayValue="name" // Property name to display in the dropdown options
                                                                placeholder="Select Job Title"
                                                                style={multiSelectStyle}
                                                                singleSelect={true}
                                                                onChange={props.handleChange('categoryAndJobTitleId')}
                                                            // onBlur={props.handleBlur('categoryAndJobTitleId')}
                                                            // defaultValue={props.values.categoryAndJobTitleId}
                                                            />
                                                            <p style={{ color: 'red' }}>{props.touched.categoryAndJobTitleId && props.errors.categoryAndJobTitleId}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Job Description</label>
                                                        <div className="col-sm-9">
                                                            <textarea
                                                                className="form-control"
                                                                placeholder="Job Description"
                                                                onChange={props.handleChange('companyJobDescription')}
                                                                onBlur={props.handleBlur('companyJobDescription')}
                                                                defaultValue={props.values.companyJobDescription}
                                                            />
                                                            <p style={{ color: 'red' }}>{props.touched.companyJobDescription && props.errors.companyJobDescription}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label"></label>
                                                        <div className="col-sm-9">
                                                            <button
                                                                className="button"
                                                                type='submit'
                                                                onClick={props.handleSubmit}
                                                            >Post Job Description</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Formik>
                                    </form>
                                </div>
                                <EmployerLeftSideBar employerName="DSHG Sonic LLC" activeMenu="postNewJobs" companyProfile={companyProfile} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactJsAlert
                status={alertStatus} // true or false
                type={type} // success, warning, error, info
                title={title}
                autoCloseIn='30000'
                Close={() => { setAlertStatus(false);  navigate("/EmployerProfile") }}
            />

            {/* Job Listing End */}
            {/* Optional JavaScript */}
            {/* jQuery first, then Popper.js, then Bootstrap JS */}
        </>
    )
};

export default PostJobDescription;