import React from 'react'
import { NavLink } from 'react-router-dom'

export const Cancel = () => {
  return (
    <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '20%' }}>
      <img src={require("./images/Failure.jpg")} alt="Payment Successful!" width={'100%'} />
      <h5 style={{ color: '#360A65', marginLeft: '30px', marginRight: 'auto', width: '100%' }}>Payment Cancled!</h5>
      <NavLink
        to={"/pricing"}
        style={{ color: '#fff', width: '100%', marginBottom: 30, marginTop: 20 }}
        className="banner-btn"
      >
        Try Again!
      </NavLink>
    </div>
  )
}
export default Cancel