import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/themify-icons.css';
import './assets/css/et-line.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/plyr.css';
import './assets/css/flag.css';
import './assets/css/slick.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/jquery.nstSlider.min.css';
import './css/main.css';
import './assets/css/dashboard.css';
import { NavLink } from "react-router-dom";
import { useEffect, useState } from 'react';
import Axios from 'axios';


const EmployerLetSideBar = ({ employerName, activeMenu, companyProfile }) => {

    let total = 0;
    if (companyProfile.companyName !== null && companyProfile.companyName !== '')
        total = total + 20;
    if (companyProfile.companyEmail !== null && companyProfile.companyEmail !== '')
        total = total + 20;
    if (companyProfile.companyPhoneNumber !== null && companyProfile.companyPhoneNumber !== '')
        total = total + 15;
    if (companyProfile.companyAddress !== null && companyProfile.companyAddress !== '')
        total = total + 15;
    if (companyProfile.companyAboutUs !== null && companyProfile.companyAboutUs !== '')
        total = total + 15;
    if (companyProfile.companyLogo !== null && companyProfile.companyLogo !== '')
        total = total + 15;

    const logout = () => {
        localStorage.setItem('companyId', '');
        window.location.href = '/'
    }

    return (
        <>
            <div className="dashboard-sidebar">
                <div className="company-info">
                    <div className="thumb">
                        {
                            companyProfile.companyLogo !== null && companyProfile.companyLogo !== '' ?
                                <img src={`${process.env.REACT_APP_BASE_URL}uploads/companyLogos/` + companyProfile.companyLogo} className="img-fluid" alt="Company Logo" />
                                :
                                <img className="img-fluid" src="dashboard/images/defaultCompanyLogo.png" alt="Company Logo" />
                        }
                    </div>
                    <div className="company-body">
                        <h5>{employerName}</h5>
                    </div>
                </div>
                <div className="profile-progress">
                    <div className="progress-item">
                        <div className="progress-head">
                            <p className="progress-on">Profile</p>
                        </div>
                        <div className="progress-body">
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow={total} aria-valuemin={0} aria-valuemax={total} style={{ width: 0 }} />
                            </div>
                            <p className="progress-to">{total}%</p>
                        </div>
                    </div>
                </div>
                <div className="dashboard-menu">
                    <ul>
                        <li className={activeMenu === "dashboard" ? "active" : ""}><i className="fas fa-home" /><NavLink to="/EmployerProfile" state={companyProfile}>Dashboard</NavLink></li>
                        <li className={activeMenu === "editProfile" ? "active" : ""}><i className="fas fa-user" /><NavLink to="/EditEmployerProfile" state={companyProfile}>Edit Profile</NavLink></li>
                        <li className={activeMenu === "manageJobs" ? "active" : ""}><i className="fas fa-briefcase" /><NavLink to="/ManageJobs" state={companyProfile}>Manage Jobs</NavLink></li>
                        <li className={activeMenu === "postNewJobs" ? "active" : ""}><i className="fas fa-plus-square" /><NavLink to="/PostJobDescription" state={companyProfile}>Post New Job</NavLink></li>
                        <li className={activeMenu === "favoriteCandidates" ? "active" : ""}><i className="fas fa-users" /><NavLink to="/FavoriteCandidates" state={companyProfile}>Favorite Candidates</NavLink></li>
                        <li className={activeMenu === "interviewAndOfferRequests" ? "active" : ""}><i className="fas fa-hand-point-up" /><NavLink to="/InterviewAndOfferRequests" state={companyProfile}>Interview & Offer Requests</NavLink></li>
                    </ul>
                    <ul className="delete">
                        <li><i className="fas fa-power-off" /><a href="#" onClick={logout}>Logout</a></li>
                        {/* <li><i className="fas fa-trash-alt" /><a href="#" data-toggle="modal" data-target="#modal-delete">Delete Profile</a></li> */}
                    </ul>
                    {/* Modal */}
                    <div className="modal fade modal-delete" id="modal-delete" tabIndex={-1} role="dialog" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <h4><i data-feather="trash-2" />Delete Account</h4>
                                    <p>Are you sure! You want to delete your profile. This can't be undone!</p>
                                    <form action="#">
                                        <div className="form-group">
                                            <input type="password" className="form-control" placeholder="Enter password" />
                                        </div>
                                        <div className="buttons">
                                            <button className="delete-button">Save Update</button>
                                            <button className>Cancel</button>
                                        </div>
                                        <div className="form-group form-check">
                                            <input type="checkbox" className="form-check-input" defaultChecked />
                                            <label className="form-check-label">You accepts our <a href="#">Terms and Conditions</a> and <a href="#">Privacy Policy</a></label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default EmployerLetSideBar;