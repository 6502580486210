import React from 'react'

const TermsAndConditions = () => {
    return (
        <div style={{ padding: 20 }}>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <strong>
                    <span style={{ fontFamily: 'Poppins', color: '#0A0A0A', border: 'none windowtext 1.0pt', padding: '0in' }}>
                        Last Updated:
                    </span>
                </strong>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;January 1, 2024
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    Terms and Conditions for Fludder
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    Welcome to Fludder ("we," "us," or "our"). Please carefully read the following terms and conditions ("Terms") before using our website and mobile application (collectively, the "Platform"). By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use the Platform.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    1. Acceptance of Terms
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    By accessing or using the Platform, you agree to these Terms, our Privacy Policy, and any additional terms and conditions that may apply to specific features of the Platform. If you do not agree to these Terms, please discontinue use of the Platform immediately.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    2. User Eligibility
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    You must be 18 years or older to use the Platform. By using the Platform, you represent and warrant that you meet this eligibility requirement.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    3. Registration
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    To access certain features of the Platform, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    4. User Content
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    Users may submit content, including but not limited to resumes, profiles, video uploads and job postings. By submitting content to the Platform, you grant us a non-exclusive, worldwide, royalty-free, sublicensable, transferable right and license to use, host, store, reproduce, modify, create derivative works, communicate, publish, publicly perform, publicly display, and distribute such content.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    5. Candidate Profiles
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    Candidates are solely responsible for the accuracy of their employment profiles and all related videos and information. We do not guarantee the accuracy or completeness of any candidate data that we display on our Platform.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    6. Platform Access and Availability
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    We reserve the right to modify, suspend, or discontinue the Platform at any time without notice. We will not be liable if, for any reason, all or any part of the Platform is unavailable at any time or for any period.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    7. User Conduct
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    You agree not to engage in any of the following prohibited activities: (a) use the Platform for any illegal purpose or in violation of any local, state, national, or international law; (b) engage in any activity that disrupts, interferes with, or harms the Platform; (c) transmit any viruses, worms, defects, Trojan horses, or any items of a destructive nature; (d) attempt to gain unauthorized access to the Platform, user accounts, or other computer systems or networks; and (e) engage in any conduct that restricts or inhibits any other user from using or enjoying the Platform.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    8. Termination
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    We reserve the right to terminate or suspend your account and access to the Platform at our sole discretion, without notice, for any reason, including but not limited to a violation of these Terms.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    9. Disclaimer of Warranties
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    THE PLATFORM IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    10. Limitation of Liability
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    IN NO EVENT WILL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (a) YOUR USE OR INABILITY TO USE THE PLATFORM; (b) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY PERSONAL INFORMATION STORED THEREIN; (c) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM; (d) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR PLATFORM BY ANY THIRD PARTY; (e) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE PLATFORM.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    11. Governing Law
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    These Terms are governed by and construed in accordance with the laws of the State of Connecticut. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located within the State of Connecticut.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '24px', fontFamily: 'Poppins', color: '#330170' }}>
                    Contact Us
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    If you have any questions about these Terms, please contact us at info@fludder.io.
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#0A0A0A' }}>
                    &nbsp;
                </span>
            </p>
            <p style={{ margin: '0in', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '19.2pt', verticalAlign: 'baseline' }}>
                <span style={{ fontSize: '18px', fontFamily: 'Poppins', color: '#666666' }}>
                    Thank you for using Fludder!
                </span>
            </p>
        </div>
    )
}

export default TermsAndConditions